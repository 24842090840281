import {
	FileImageOutlined,
	ForwardOutlined,
	SendOutlined,
	MessageOutlined,
	WechatOutlined,
	LikeFilled,
	DislikeFilled,
	CloseOutlined,
} from '@ant-design/icons';
import { getInitials } from '../../../../../helpers/getStringInitials';
import { OrderStateType } from '../../../types/OrderStateType';
import './ChatContainer.scss';
import { useState, useMemo, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTimeAgo from 'react-time-ago';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../../assets/api/endpoints';
import { getUserInfo } from '../../../../../helpers/localStorageHandler';
import { MessageType } from '../../../types/MessageType';
import MessageItem from './MessageItem/MessageItem';
import Stomp from 'stompjs';
import TextArea from 'antd/es/input/TextArea';
import { Form, Grid, Input, Skeleton, notification } from 'antd';
import { useLocation } from 'react-router-dom';
import { getLanguage } from 'helpers/getLanguage';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
import EmojiPicker from 'emoji-picker-react';
import { SmileOutlined } from '@ant-design/icons';
import { useDirectionContext } from 'store/DirectionContext';
import { OrderDetailsType } from 'pages/OrderLifeCycle/types/OrderDetailsType';
import { INPUT_CHAT_VALIDATION } from 'assets/config/validation-regex';

type ChatContainerProps = {
	isMerchant: boolean;
	orderNumber: string;
	orderState: OrderStateType;
	userName: string;
	orders: number;
	reviews: number;
	rating: number;
	stompClient: Stomp.Client | null;
	getOrderDetails: () => void;
	setIsActionsDisabled: Dispatch<SetStateAction<boolean>>;
	setIsActionsHidden: Dispatch<SetStateAction<boolean>>;
	clientOrder: number;
	clientCompletedOrders: number;
	merchantDetails: OrderDetailsType['merchant'];
};

const iconStyle = {
	color: '#ffffff',
	background: 'linear-gradient(#00afff, #0077ff)',
	borderRadius: '50%',
	width: '26px',
	height: '26px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '5px',
};

const REVIEW_PERCENTAGE = 80;
const UNREADEMESAAGES = 99;
function ChatContainer({
	isMerchant,
	orderNumber,
	orderState,
	userName,
	orders,
	reviews,
	rating,
	stompClient,
	getOrderDetails,
	setIsActionsDisabled,
	setIsActionsHidden,
	clientOrder,
	clientCompletedOrders,
	merchantDetails,
}: ChatContainerProps) {
	const { t } = useTranslation();
	const location = useLocation();
	const [chatForm] = Form.useForm();
	const { xs } = Grid.useBreakpoint();
	const [showChat, setShowChat] = useState({ value: true });
	const bottomRef = useRef<null | HTMLElement>(null);
	const token = getUserInfo()?.token;
	const myUserName = useMemo(() => getUserInfo()?.fullName, []);
	const [userLastSeen, setUserLastSeen] = useState<Date>(new Date());
	const isChatDisabled = useMemo(() => orderState === 'CANCELLED' || orderState === 'REJECTED', [orderState]);
	const [isChatTerminated, setIsChatTernimated] = useState(false);
	const loggedInUserRole = useMemo(() => (isMerchant ? 'MERCHANT' : 'CLIENT'), [isMerchant]);
	const isIncomingMessge = (msg: any) => msg.role !== loggedInUserRole;
	const [isUserOnline, setIsUserOnline] = useState(false);
	const [historicalMessages, setHistoicalMessages] = useState<MessageType[]>([]);
	const [liveMessages, setLiveMessages] = useState<MessageType[]>([]);
	const [isChatConnected, setIsChatConnected] = useState(false);
	const [text, setText] = useState('');
	const [unreadMessages, setUnreadMessages] = useState(0);
	const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
	const [disabledSend, setDisabledSend] = useState(false);

	const isChatNotAvailable = useMemo(
		() => isChatDisabled || isChatTerminated || !isChatConnected,
		[isChatDisabled, isChatTerminated, isChatConnected],
	);
	const chatTopic = useMemo(
		() => API_ENDPOINTS.getOrderChatMsgTopic.replace('%orderNumber%', orderNumber),
		[orderNumber],
	);
	const sendTopic = useMemo(
		() => API_ENDPOINTS.sendOrderChatMsgTopic.replace('%orderNumber%', orderNumber),
		[orderNumber],
	);
	const { directionState } = useDirectionContext();
	const allowedMimeTypes = ['image/jpeg', 'image/png', 'application/pdf'];
	const maxFileSize = 5 * 1024 * 1024;
	// When status is EXPIRE => check for any action in chat
	// YES => show actions + enable chat. NO => hide actions + disable chat
	useEffect(() => {
		if (orderState === 'EXPIRED') {
			const didActionHappen = [...historicalMessages, ...liveMessages].some((msg) => msg.type === 'ACTION');
			setIsActionsHidden(!didActionHappen);
			setIsChatTernimated(!didActionHappen);
		}
	}, [orderState, location.search]);

	useEffect(() => {
		// Start the WS connect in case it's allowed
		if (!isChatDisabled && !isChatTerminated) {
			stompClient?.connect(
				{ orderNumber, Authorization: `Bearer ${token}` },
				() => {
					setIsChatConnected(true);
					setIsActionsDisabled(false);
					stompClient?.subscribe(chatTopic, onMessageReceived);
					stompClient?.send(sendTopic, {}, JSON.stringify({ type: 'JOIN' }));
					axios
						.get(API_ENDPOINTS.getChats.replace('%orderNumber%', orderNumber), {
							headers: { Authorization: `Bearer ${token}` },
						})
						.then(async (response) => {
							const messages: MessageType[] = response.data.chatMessages;
							setHistoicalMessages(messages);
							const doesContainAction = messages?.some((message) => message.type === 'ACTION');
							setIsChatTernimated(!doesContainAction && orderState === 'EXPIRED');
							setUserLastSeen(isMerchant ? response.data.clientLastActive : response.data.merchantLastActive);
							scrollToChatBottom();
							const historicalMessagesReversed = [...messages].reverse();
							const lastUnreadMessage = historicalMessagesReversed.find((message) => !message.read);
							if (lastUnreadMessage) {
								sendReceivedNotification(lastUnreadMessage);
								setHistoicalMessages((messages) =>
									messages.map((message) => ({
										...message,
										read: message.read ? message.read : message.role !== loggedInUserRole,
									})),
								);
							}
						});
				},
				(error: any) => console.log(error),
			);
		}
	}, [stompClient, location.search]);

	useEffect(() => {
		if (isChatConnected && (isChatDisabled || isChatTerminated)) {
			stompClient?.disconnect(
				() => {
					setIsChatConnected(false);
					setIsActionsDisabled(true);
				},
				{
					orderNumber,
					Authorization: `Bearer ${token}`,
				},
			);
		}
	}, [isChatDisabled, isChatTerminated, location.search]);

	const onMessageReceived = (data: any) => {
		const newMessage: MessageType = JSON.parse(data.body);
		setLiveMessages((oldMessages) => [...oldMessages, newMessage]);
		if (isIncomingMessge(newMessage)) {
			if (newMessage.messageId) {
				setHistoicalMessages((messages) => messages.map((message) => ({ ...message, read: true })));
				setLiveMessages((messages) => messages.map((message) => ({ ...message, read: true })));
			}
			if (
				newMessage.type === 'TEXT' ||
				newMessage.type === 'FILE' ||
				newMessage.type === 'ACTION' ||
				newMessage.type === 'JOIN'
			) {
				setShowChat((prevState) => {
					if (prevState.value) {
						sendReceivedNotification(newMessage);
					} else {
						setUnreadMessages((count) => count + 1);
					}
					return prevState;
				});
			}
			if (newMessage.type === 'RECEIVED' || newMessage.type === 'JOIN') {
				setIsUserOnline(true);
			} else if (newMessage.type === 'LEAVE') {
				setIsUserOnline(false);
				setUserLastSeen(new Date());
			}
		}

		if (newMessage.type === 'TERMINATED') {
			setIsChatTernimated(true);
		} else if (newMessage.type === 'ACTION') {
			getOrderDetails();
		}

		scrollToChatBottom();
	};

	const onChatKeyUp = (event: any) => {
		event.preventDefault();

		if (event.keyCode === 13 && !disabledSend) {
			onMessageSend();
		}
	};

	const onMessageSend = () => {
		const content = chatForm.getFieldValue('currentMessage');

		if (stompClient && content.trim()) {
			stompClient?.send(sendTopic, {}, JSON.stringify({ sender: myUserName, content, type: 'TEXT' }));
			chatForm.setFieldValue('currentMessage', '');
		}
	};

	const sendReceivedNotification = (message: any) => {
		stompClient?.send(
			sendTopic,
			{},
			JSON.stringify({ messageId: message.messageId, role: loggedInUserRole, type: 'RECEIVED' }),
		);
	};
	const getHistoricalMessages = () => {
		setHistoicalMessages([]);
		axios
			.get(API_ENDPOINTS.getChats.replace('%orderNumber%', orderNumber), {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(async (response) => {
				const messages: MessageType[] = response.data.chatMessages;

				setHistoicalMessages(messages);
				const doesContainAction = messages?.some((message) => message.type === 'ACTION');
				setIsChatTernimated(!doesContainAction && orderState === 'EXPIRED');
				setUserLastSeen(isMerchant ? response.data.clientLastActive : response.data.merchantLastActive);
				scrollToChatBottom();
			});
	};

	const scrollToChatBottom = () => {
		setTimeout(() => {
			bottomRef?.current?.scroll({
				top: Number(document.querySelector('.chat-message-container')?.clientHeight || 0),
				left: 0,
				behavior: 'smooth',
			});
		}, 500);
	};

	const handleUploadFileClick = (event: any) => {
		const files = event.target?.files;
		const uploadedFile = files[0];

		if (!uploadedFile) {
			return;
		}

		if (!allowedMimeTypes.includes(uploadedFile.type)) {
			notification['error']({
				message: '',
				description: t<string>('invalidType'),
			});
			return;
		}
		if (uploadedFile.size > maxFileSize) {
			notification['error']({
				message: '',
				description: t<string>('fileExceedLimit'),
			});
			return;
		}
		const formData = new FormData();
		formData.append('file', uploadedFile);

		axios
			.post(API_ENDPOINTS.uploadFile + orderNumber, formData, {
				headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
			})
			.then((result) => {
				if (result && stompClient) {
					const chatMessage = {
						sender: userName,
						content: '',
						type: 'FILE',
						fileId: result.data.fileId,
						fileLabel: result.data.fileLabel,
					};
					stompClient.send(sendTopic, {}, JSON.stringify(chatMessage));
				}
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		setHistoicalMessages([]);
		setLiveMessages([]);
	}, [location.search]);
	useEffect(() => {
		if (showChat.value) {
			const historicalMessagesReversed = [...historicalMessages].reverse();
			if (historicalMessagesReversed[0]) {
				sendReceivedNotification(historicalMessagesReversed[0]);
			}
		} else {
			setUnreadMessages(0);
		}
	}, [showChat]);

	const handleEmojiClick = (emojiData: any) => {
		setOpenEmojiPicker(false);
		const content = chatForm.getFieldValue('currentMessage') ?? '';
		setText((prevMessage) => prevMessage + emojiData.emoji);
		chatForm.setFieldValue('currentMessage', content + emojiData.emoji);
	};

	useEffect(() => setShowChat({ value: !Boolean(xs) }), [xs]);
	return (
		<div className='chatting-with-other-party-container'>
			{orderState ? (
				<>
					{showChat.value ? null : (
						<button
							style={{
								top: '400px',
								left: getUserLanguageInCookie() === 'ar' ? '10px' : 'unset',
							}}
							className='open-chat-button rounded-xl'
							onClick={() => setShowChat({ value: true })}
						>
							{unreadMessages ? (
								<span className='bill-icon'>
									{unreadMessages > UNREADEMESAAGES ? `+${UNREADEMESAAGES}` : unreadMessages}
								</span>
							) : (
								<span className='bill-icon-empty'></span>
							)}
							<p className='text-[15px]'>{t<string>('chatnow')}</p>
							<MessageOutlined />
						</button>
					)}
					<div className={`order-life-cycle-chat-container ${showChat.value ? '' : 'hidden'}`}>
						<button className='close-chat-button' onClick={() => setShowChat({ value: false })}>
							<ForwardOutlined />
						</button>
						<div className='order-life-cycle-chat-box-header'>
							<div className='header-container'>
								<span className='user-menu-details-initials'>{getInitials(userName)}</span>
								<div className='user-details flex justify-between'>
									<div className='flex flex-col justify-center w-[60%]'>
										<p className='user-name'>
											{userName}
											{!isMerchant && (
												<span
													className={`text-xs mx-2 font-normal ${
														rating < REVIEW_PERCENTAGE ? 'text-[#F6465D]' : 'text-[#33A867]'
													}`}
												>
													{rating < REVIEW_PERCENTAGE ? (
														<DislikeFilled className='rating-icon text-[#F6465D] mx-1' />
													) : (
														<LikeFilled className='rating-icon text-[#33A867] mx-1' />
													)}
													{rating}%
												</span>
											)}
										</p>
										<div className='content'>
											{isMerchant ? (
												<>
													<span dir='ltr' className='text-[11px]'>
														{Number(clientCompletedOrders)} {t<string>('orders')}
													</span>
													<span className='vertical-seperator'></span>
													<span dir='ltr' className='text-[11px]'>
														{(
															(Number(clientCompletedOrders) /
																(clientOrder === null || clientOrder === 0 ? 1 : Number(clientOrder))) *
															100
														).toFixed()}
														% {t<string>('completionRate')}
													</span>
												</>
											) : (
												<>
													<span dir='ltr' className='text-xs'>
														{merchantDetails?.ordersCompleted} {t<string>('orders')}
													</span>
													<span className='vertical-seperator'></span>
													<span dir='ltr' className='text-[11px]'>
														{(
															(Number(merchantDetails?.ordersCompleted ?? 0) /
																(merchantDetails?.totalOrders === null || merchantDetails?.totalOrders === 0
																	? 1
																	: Number(merchantDetails?.totalOrders))) *
															100
														).toFixed()}
														% {t<string>('completionRate')}
													</span>
												</>
											)}
										</div>
									</div>
									<div className='flex flex-col items-end justify-start'>
										<CloseOutlined
											className='close w-[12px] m-1 '
											alt='close'
											onClick={() => setShowChat({ value: false })}
										/>
										{isUserOnline ? (
											<p className='live'>{t<string>('live')}</p>
										) : (
											<p className='last-seen'>
												{userLastSeen ? (
													<ReactTimeAgo date={userLastSeen} locale={getLanguage(getUserLanguageInCookie())} />
												) : null}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className='order-life-cycle-chat-box-body' ref={bottomRef as any}>
							<div className='chat-message-container'>
								<p style={{ display: 'flex', justifyContent: 'center', fontSize: '40px', width: '100%' }}>
									<WechatOutlined />
								</p>
								{[...historicalMessages, ...liveMessages].map((message, index) => (
									<MessageItem
										isMerchant={isMerchant}
										message={message}
										orderNumber={orderNumber}
										key={index}
										userName={userName}
									/>
								))}
							</div>
						</div>
						<div className='order-life-cycle-chat-box-footer'>
							{isChatDisabled ? (
								<span style={{ width: '100%', textAlign: 'center', fontSize: '14px', color: '#bdbdbd' }}>
									{t<string>('chatIsDisabled')}
								</span>
							) : (
								<Form
									name='chatForm'
									form={chatForm}
									className='chat-form'
									onValuesChange={() => setText(chatForm.getFieldValue('currentMessage')?.trim())}
								>
									<Form.Item style={{ width: '26px', display: 'flex', alignItems: 'center' }}>
										<label>
											<Input
												type='file'
												className='hide'
												disabled={isChatNotAvailable || !stompClient?.connected}
												onChange={handleUploadFileClick}
											/>
											<FileImageOutlined style={{ ...iconStyle }} />
										</label>
									</Form.Item>
									<Form.Item
										name='currentMessage'
										rules={[
											{
												validator: (_, value) => {
													if (INPUT_CHAT_VALIDATION.test(value)) {
														setDisabledSend(false);
														return Promise.resolve();
													}
													setDisabledSend(true);
													return Promise.reject(new Error(t('validationChatMessage') as string));
												},
											},
										]}
									>
										<TextArea
											disabled={isChatNotAvailable || !stompClient?.connected}
											placeholder={t<string>('typeMessageHere')}
											autoSize={{ minRows: 1, maxRows: 3 }}
											onPressEnter={onChatKeyUp}
											style={{ paddingInlineEnd: '30px' }}
										/>
									</Form.Item>
									<SmileOutlined
										className='custom-icon'
										onClick={() => setOpenEmojiPicker((prvState) => !prvState)}
										style={{
											left: directionState === 'rtl' ? '60px' : 'unset',
											right: directionState === 'ltr' ? '60px' : 'unset',
										}}
									/>
									<EmojiPicker open={openEmojiPicker} onEmojiClick={handleEmojiClick} className='emoji-picker' />
									<button
										disabled={isChatNotAvailable || !text || !stompClient?.connected || disabledSend}
										onClick={onMessageSend}
									>
										<SendOutlined style={{ ...iconStyle, transform: 'rotate(-30deg)', padding: '5px 5px 5px 7px' }} />
									</button>
								</Form>
							)}
						</div>
					</div>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 7 }} />
			)}
		</div>
	);
}

export default ChatContainer;
