import { Modal, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd';
import { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useConfigurationContext } from 'store/ConfigurationContext';

import { CompanyName } from '../../../../../../../assets/constant/fallback-config';

export function CancelReason({
	showCancelReason,
	setShowCancelReason,
	handelCancelOrder,
	loading,
}: {
	showCancelReason: boolean;
	setShowCancelReason: (value: boolean) => void;
	handelCancelOrder: (reasons: string) => void;
	loading: boolean;
}) {
	const { t } = useTranslation();
	const [value, setValue] = useState('');
	const [showTextBox, setShowTextBox] = useState(false);
	const [reasons, setReasons] = useState('');

	const { configurationState } = useConfigurationContext();
	const company_name = configurationState.find((item) => item.configKey === 'Company_Name')?.value ?? CompanyName;

	const onChange = (e: RadioChangeEvent) => {
		const cancelReason = e.target.value;

		setValue(cancelReason);
		setShowTextBox(cancelReason === 'others');
		setReasons(t<string>(`${cancelReason}`));
	};

	return (
		<Modal
			title={
				<div className='flex'>
					<ExclamationCircleOutlined />
					<h4 className='m-0 p-0 text-center ml-2'>{t<string>('resonsForCancellation')}</h4>
				</div>
			}
			open={showCancelReason}
			onCancel={() => setShowCancelReason(false)}
			footer={null}
		>
			<p>{t<string>('resonsForCancellationNote', { company_name })}</p>
			<div className='w-[100%] h-[1px]  bg-[#d4d8e0] my-' />
			<div className='my-4'>
				<Radio.Group onChange={onChange} value={value}>
					<Space direction='vertical'>
						<Radio value={'unableToMakePayment'}>{t<string>('unableToMakePayment')}</Radio>
						<Radio value={'dontKnowHowToPay'}>{t<string>('dontKnowHowToPay')}</Radio>
						<Radio value={'discussedWithSeller'}>{t<string>('discussedWithSeller')}</Radio>
						<Radio value={'poorSellerAttitude'}>{t<string>('poorSellerAttitude')}</Radio>
						<Radio value={'cantContactSeller'}>{t<string>('cantContactSeller')}</Radio>
						<Radio value={'suspectSellerFraud'}>{t<string>('suspectSellerFraud')}</Radio>
						<Radio value={'others'}>{t<string>('others')}</Radio>
					</Space>
				</Radio.Group>

				{showTextBox && (
					<textarea
						className='resize-none p-4 m-4 rounded ml-0'
						style={{ maxWidth: '-webkit-fill-available' }}
						rows={4}
						cols={60}
						placeholder={t<string>('pleaseWriteYourNote')}
						onChange={(e) => setReasons(e.target.value)}
					/>
				)}
			</div>

			<div className='w-[100%] h-[1px]  bg-[#d4d8e0] my-2' />
			<div className='flex justify-end'>
				<button
					className='p-[10px] border-solid rounded border-[1px] w-[120px] bg-transparent mr-[10px]'
					onClick={() => {
						setShowCancelReason(false);
					}}
				>
					{t<string>('back')}
				</button>
				<Spin spinning={loading}>
					<button
						className='p-[10px] border-none rounded border-[1px] w-[120px] mr-[10px] bg-[#0192ff]'
						disabled={!reasons}
						style={{ backgroundColor: reasons ? '#01a0ff' : '#eaecef' }}
						onClick={() => {
							handelCancelOrder(reasons);
							setShowCancelReason(false);
						}}
					>
						{t<string>('submit')}
					</button>
				</Spin>
			</div>
		</Modal>
	);
}
