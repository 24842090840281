import { useEffect } from 'react';

/**
 * Keeps the Chat component hidden while the component is still mounted.
 *
 * Triggered when component is mounted, and on each update.
 *
 * @returns {void}
 */
export function useHideTidioChat(): void {
	useEffect(() => {
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList') {
					const tidioChatIframe = document.getElementById('tidio-chat-iframe');
					if (tidioChatIframe) {
						tidioChatIframe.style.setProperty('display', 'none');
					}

					const widgetButton = document.querySelector('[data-testid="widgetButton"]') as HTMLElement | null;
					if (widgetButton) {
						widgetButton.style.setProperty('display', 'none');
					}
				}
			});
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
			const tidioChatIframe = document.getElementById('tidio-chat-iframe');
			if (tidioChatIframe) {
				tidioChatIframe.style.setProperty('display', 'block');
			}
			const widgetButton = document.querySelector('[data-testid="widgetButton"]') as HTMLElement | null;
			if (widgetButton) {
				widgetButton.style.setProperty('display', 'block');
			}
		};
	}, []);
}
