import { FC, useEffect, useMemo, useState } from 'react';
import { Merchant, OfferType } from '../../models/Merchants';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import { getInitials } from '../../helpers/getStringInitials';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PaymentMethod } from '../../models/PaymentMethod';
import SelectPaymentMethods from '../SelectPaymentMethods/SelectPaymentMethods';
import SelectAccount from '../SelectAccount/SelectAccount';
import './MerchantBuyAndSellModal.scss';
import { MyAccount } from '../../models/MyAccounts';
import { getUserInfo } from '../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { Tooltip, Spin } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons';
import { PriceChangeModal } from './PriceChangeModal';

interface Props {
	merchant: Merchant;
	paymentMethods: PaymentMethod[];
	accounts: MyAccount[];
	closeModal: () => void;
	offerType: OfferType;
	setRefersh: (value: boolean) => void;
	refersh: boolean;
}

const REVIEW_PERCENTAGE = 80;
const MerchantBuyAndSellModal: FC<Props> = ({
	merchant,
	paymentMethods,
	accounts,
	closeModal,
	offerType,
	setRefersh,
	refersh,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isAmountTouched, setIsAmountTouched] = useState(false);
	const [amount, setAmount] = useState<string | null | number>('');
	const [selectedMethod, setSelectedMethod] = useState('');
	const [selectedAccount, setSelectedAccount] = useState('');
	const [canOpenOrder, setCanOpenOrder] = useState(false);
	const [amountError, setAmountError] = useState('');
	const [noteError, setNoteError] = useState('');
	const [account, setAccount] = useState<MyAccount>();
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [price, setPrice] = useState(merchant.rate);

	const isBuy = useMemo(() => (offerType === OfferType.buy ? true : false), [offerType]);
	const truncateToThreeDecimalPlaces = (number: any) => {
		return Math.floor(number * 1000) / 1000;
	};
	const onAmountChange = (amountValue: string) => {
		setAmount(amountValue === '0' || amountValue === '' ? null : parseFloat(Number(amountValue).toFixed(3)));
		setIsAmountTouched(true);
	};
	const calculatedAmount = useMemo(() => {
		if (!amount) {
			return '0.00';
		}
		return isBuy ? truncateToThreeDecimalPlaces(+amount / price) : truncateToThreeDecimalPlaces(+amount * price);
	}, [isBuy, amount, price]);
	const offerPaymentMethods = useMemo(
		() => paymentMethods?.filter((method) => merchant.paymentMethods.includes(method.methodId)),
		[paymentMethods, merchant],
	);
	const [clientNote, setClientNote] = useState<String>('');
	const token = getUserInfo()?.token;

	const isUserAmountValid = useMemo(() => {
		if (Number(amount) <= 0) {
			setAmountError(t<string>('amountLessThanMerchantAvailable'));
			return false;
		}
		if (isBuy) {
			const isBuyValueValid =
				Number(calculatedAmount) < Number(merchant.available) &&
				Number(amount) >= Number(merchant.min) &&
				Number(amount) <= Number(merchant.max);
			setAmountError(isBuyValueValid ? '' : t<string>('amountLessThanMerchantAvailable'));
			return isBuyValueValid;
		} else {
			const accountBalance = accounts.find((account) => account.number === selectedAccount)?.balance ?? 0;
			const isSellValueValid = Number(amount) <= Number(accountBalance);
			setAmountError(isSellValueValid ? '' : t<string>('amountLessThanYourBalanceLimit'));
			return isSellValueValid;
		}
	}, [
		amount,
		calculatedAmount,
		merchant.available,
		selectedAccount,
		accounts,
		isBuy,
		clientNote,
		merchant.max,
		merchant.min,
		t,
	]);
	const isOfferDisabled = useMemo(
		() => !amount || !selectedMethod || !selectedAccount || !canOpenOrder || !isUserAmountValid || Boolean(noteError),
		[amount, selectedMethod, selectedAccount, canOpenOrder, isUserAmountValid, noteError],
	);

	const isDev = window.location.hostname.includes('devb');
	useEffect(() => {
		if (clientNote.length > 1000) {
			setNoteError(t<string>('noteErrorMessage'));
		} else {
			setNoteError('');
		}
	}, [clientNote, t]);
	useEffect(() => {
		axios
			.get(API_ENDPOINTS.canOpenOrder, { headers: { Authorization: `Bearer ${token}` } })
			.then((result) => setCanOpenOrder(result.data))
			.catch((error) => console.error(error));
	}, [token]);

	const navigateToOrderLifeCyclePage = async () => {
		const account = accounts.find((account) => account.number === selectedAccount);
		const tradingPlatform = account?.tradingPlatform;

		let openOrderData = {
			type: isBuy ? 'BUY' : 'SELL',
			merchantId: merchant.merchantId,
			amount: isBuy ? Number(amount) : Number(calculatedAmount),
			quantity: isBuy ? Number(calculatedAmount) : Number(amount),
			tradingPlatform: tradingPlatform,
			tradingAccount: selectedAccount,
			paymentMethod: selectedMethod,
			note: clientNote,
			price: price,
		};

		if (openOrderData && token) {
			setLoading(true);
			try {
				let exchangePriceChane = await axios.post(
					API_ENDPOINTS.exhangePrice,
					{
						price: merchant.rate,
						merchantId: merchant.merchantId,
						orderType: isBuy ? 'BUY' : 'SELL',
					},
					{
						headers: { Authorization: `Bearer ${token}` },
					},
				);
				if (!exchangePriceChane.data.priceChanged) {
					const result = await axios.post(API_ENDPOINTS.openOrder, openOrderData, {
						headers: { Authorization: `Bearer ${token}` },
					});

					if (result.status === 201) {
						setLoading(false);
						navigate(`/order-life-cycle?id=${result.data.orderNumber}`);
					}
				} else {
					setShowModal(true);
					setPrice(exchangePriceChane.data.price);
					setRefersh(!refersh);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		const account = accounts.find((acc) => acc.number === selectedAccount);
		setAccount(account);
	}, [selectedAccount, accounts]);
	return (
		<>
			<div className='merchant-buy-sell'>
				<div className='modal'>
					<CloseOutlined
						className='close-modal-icon '
						onClick={closeModal}
						dir={getUserLanguageInCookie() === 'ar' ? 'rtl' : 'ltr'}
					/>
					<div className='modal-container container'>
						<div className={getUserLanguageInCookie() === 'ar' ? 'model-info-ar' : 'model-info'}>
							<div className='merchant-info'>
								<div
									className={`modal-merchant-initials ${
										getUserLanguageInCookie() === 'ar' ? 'margin-left-2' : 'margin-right-2'
									}`}
								>
									{getInitials(merchant.fullName)}
								</div>
								<div className='w-[90%] break-words'>
									<p className='merchant-name'>{merchant.fullName}</p>
									<p className='merchant-order'>
										{merchant.ordersCompleted} {t<string>('orders')}
										<span className='inline-block mx-[3px]' />
										<span className='vr' />
										{(
											(Number(merchant?.ordersCompleted) /
												(merchant?.totalOrders === null || merchant?.totalOrders === 0
													? 1
													: Number(merchant?.totalOrders))) *
											100
										).toFixed()}
									</p>
									<div className='flex merchant-order'>
										{merchant.totalReviews} {t<string>('reviews')}
										<span className='vr' />
										<p
											className={`rating ${merchant.rating < REVIEW_PERCENTAGE ? 'text-[#F6465D]' : 'text-[#33A867]'}`}
										>
											{merchant.rating < REVIEW_PERCENTAGE ? (
												<DislikeFilled className='rating-icon' />
											) : (
												<LikeFilled className='rating-icon text-[#33A867]' />
											)}
											{merchant.rating}%
										</p>
									</div>
								</div>
							</div>
							<div className='order-info'>
								<div className='order-info-item'>
									<span>{t<string>('price')}</span>
									<span>
										{price?.toLocaleString()} {merchant.currencyCode}
									</span>
								</div>
								<div className='order-info-item'>
									<span>{t<string>('available')}</span>
									<span>
										{merchant.available?.toLocaleString()} {merchant.exchangeBaseCurrency}
									</span>
								</div>
								<div className='order-info-item'>
									<span>{t<string>('limit')}</span>
									<span>
										{merchant.min?.toLocaleString()}-{merchant.max?.toLocaleString()} {merchant.currencyCode}
									</span>
								</div>
							</div>
							<div className='hr' />
							<div className='terms-and-conditions'>
								<p className='tac-heading'>
									{t<string>(isBuy ? 'buyers' : 'sellers') + ' ' + t<string>('termsAndConditions')}
								</p>
								<p style={{ color: '#fff', maxHeight: '300px', overflowY: 'auto', overflowWrap: 'break-word' }}>
									{merchant.termsNote}
								</p>
							</div>
						</div>
						<div className='form'>
							<p className='unit-price'>
								{t<string>('unitPrice')}:{' '}
								<span>
									{price?.toLocaleString()} {merchant.currencyCode}
								</span>
							</p>
							<SelectAccount
								accounts={accounts}
								selectedAccount={selectedAccount}
								setSelectedAccount={setSelectedAccount}
							/>
							{selectedAccount && (
								<>
									<span className='my-2 mx-1'>{t<string>('accountLimit')}:</span>
									<span className='font-bold my-2'>
										{isBuy
											? `${account?.buyMin?.toLocaleString()} ~ ${account?.buyMax?.toLocaleString()}`
											: `${account?.sellMin?.toLocaleString()} ~ ${account?.sellMax?.toLocaleString()}`}{' '}
										USD
									</span>
								</>
							)}
							<label className='you-buy-or-sell my-1'>
								<div className='you-buy-or-sell-input-container '>
									<p>{isBuy ? t<string>('youPay') : t<string>('youSale')}</p>
									<input
										value={amount as string}
										type='number'
										onChange={(e) => onAmountChange(e.target.value)}
										placeholder='0.00'
										min={0}
										step={0.01}
									/>
								</div>
								<div className='you-buy-or-sell-icon'>
									<span>{isBuy ? merchant.currencyCode : merchant.exchangeBaseCurrency}</span>
								</div>
							</label>
							{amountError && isAmountTouched ? <span className='you-buy-or-sell-error'>{amountError}</span> : null}
							<label className='you-buy-or-sell disabled'>
								<div className='you-buy-or-sell-input-container'>
									<p>{t<string>('youReceive')}</p>
									<input value={calculatedAmount} type='number' placeholder='0.00' disabled style={{ color: 'gray' }} />
								</div>
								<div className='you-buy-or-sell-icon'>
									<span>{isBuy ? merchant.exchangeBaseCurrency : merchant.currencyCode}</span>
								</div>
							</label>
							<SelectPaymentMethods
								methods={offerPaymentMethods}
								selectedMethod={selectedMethod}
								setSelectedMethod={setSelectedMethod}
							/>
							<div className='payment-note'>
								<p>{t<string>('note')}</p>
								<textarea
									rows={3}
									cols={50}
									placeholder={t<string>('pleaseWriteYourNote')}
									onChange={(e) => setClientNote(e.target.value)}
								/>
								{noteError && (
									<p>
										<span className='text-[red]'>{noteError}</span>
									</p>
								)}
							</div>
							<div className='note'>
								<ExclamationCircleOutlined className='flex justify-start items-start' />
								<p>{t<string>('certainPaymentMethodsMayHaveFees')}</p>
							</div>
							<Spin spinning={loading}>
								<button className='main-btn' onClick={() => navigateToOrderLifeCyclePage()} disabled={isOfferDisabled}>
									<Tooltip title={canOpenOrder ? undefined : t<string>('cantOpenOrderNows')}>
										{isBuy ? t<string>(isDev ? 'buy' : 'deposit') : t<string>(isDev ? 'sell' : 'withdrawal')}{' '}
										{merchant.currency}
									</Tooltip>
								</button>
							</Spin>
						</div>
					</div>
				</div>
			</div>
			<PriceChangeModal showModal={showModal} setShowModal={setShowModal} />
		</>
	);
};

export default MerchantBuyAndSellModal;
