import React from 'react';

export const initialConfiguration = [
	{
		configId: 0,
		configKey: '',
		configValue: '',
		isPublic: true,
		value: '',
	},
];
export type Configuration = {
	configId: number;
	configKey: string;
	configValue: string;
	isPublic: boolean;
	value: string;
};
type Store = {
	configurationState: Configuration[];
	setConfigurationState: React.Dispatch<React.SetStateAction<Configuration[]>>;
};

export const ConfigurationContext = React.createContext<Store>({
	configurationState: initialConfiguration,
} as Store);

export const useConfigurationContext = () => React.useContext(ConfigurationContext);
