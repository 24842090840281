import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { DashOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { MerchantDetails, MerchantReview } from '../../models/MerchantDetails';
import React from 'react';
import { Skeleton, Tooltip, Typography } from 'antd';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './SingleMerchant.scss';
import { getInitials } from '../../helpers/getStringInitials';
import SubHeader from '../../components/SubHeader/SubHeader';
import { useCountriesContext } from 'store/CountriesContext';
import Buy from 'assets/images/icons/Buy';
import Sell from 'assets/images/icons/Sell';
import MerchantBuyAndSellModal from 'components/MerchantBuyAndSellModal/MerchantBuyAndSellModal';
import { MyAccount } from 'models/MyAccounts';
import { Merchant, OfferType } from 'models/Merchants';
import { useMainContext } from 'store/MainContext';
import { LimitCheckModal } from 'components/MerchantBuyAndSellModal/LimitCheckModal';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';

const SplideNextButton = ({ isRTL }: { isRTL: boolean }) => (
	<button
		className='splide__arrow splide__arrow--next'
		style={isRTL ? { left: '-2rem' } : { right: '-2rem' }}
		type='button'
		aria-label='Next slide'
		aria-controls='splide01-track'
	>
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='40' height='40' focusable='false'>
			<path d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z'></path>
		</svg>
	</button>
);

const SplidePreviousButton = ({ isRTL }: { isRTL: boolean }) => (
	<button
		className='splide__arrow splide__arrow--prev'
		style={isRTL ? { right: '-2rem' } : { left: '-2rem' }}
		type='button'
		aria-label='Previous slide'
		aria-controls='splide01-track'
	>
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' width='40' height='40' focusable='false'>
			<path d='m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z'></path>
		</svg>
	</button>
);

const roundedLargeNumber = (largeNumber: number) => {
	let roundedNumber;
	if (largeNumber) {
		if (largeNumber.toString().includes('.')) {
			const [integerPart, decimalPart] = largeNumber.toString().split('.');
			const roundedDecimal = decimalPart ? decimalPart.substring(0, 3) : '000';
			roundedNumber = Number(`${integerPart}.${roundedDecimal}`);
		} else {
			roundedNumber = largeNumber;
		}
	}

	return roundedNumber;
};
const SingleMerchant = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { mainState } = useMainContext();
	const [dataLoading, setDataLoading] = useState(false);
	const [merchant, setMerchant] = useState<MerchantDetails | null>(null);
	const [reviews, setReviews] = useState<MerchantReview[]>([]);
	const [showModal, setShowModal] = useState(false);
	const [accounts, setAccounts] = useState<MyAccount[]>([]);
	const [offerType, setOfferType] = useState<OfferType.buy | OfferType.sell>(OfferType.buy);
	const [refresh, setRefersh] = useState(false);
	const isMerchant = React.useMemo(() => mainState.isMerchant, [mainState.isMerchant]);
	const merchantId = searchParams.get('id') ?? '';
	const token = getUserInfo()?.token;
	const [isLimitReached, setIsLimitReached] = useState<boolean | null>(null);
	const [isLimitReachedModal, setIsLimitReachedModal] = useState<boolean>(false);
	const { Text } = Typography;
	const { countriesState } = useCountriesContext();
	const isDev = window.location.hostname.includes('devb');
	const paymentMethodBuyer = useMemo(
		() =>
			countriesState.paymentMethods.filter((method) => merchant?.buyerOffer?.paymentMethods.includes(method.methodId)),
		[merchant?.buyerOffer?.paymentMethods, countriesState.paymentMethods],
	);
	const paymentMethodSeller = useMemo(
		() =>
			countriesState.paymentMethods.filter((method) => merchant?.sellerOffer?.paymentMethods.includes(method.methodId)),
		[merchant?.sellerOffer?.paymentMethods, countriesState.paymentMethods],
	);

	const isRTL = getUserLanguageInCookie() === 'ar';
	const isSameCountry = getUserInfo()?.countryCode === merchant?.countryCode;

	useEffect(() => {
		if (!merchantId) {
			navigate('/merchants');
		}
		setDataLoading(true);

		Promise.all([
			axios.get(API_ENDPOINTS.merchant.replace('%merchantId%', merchantId), {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.merchantReviews.replace('%merchantId%', merchantId), {
				params: { sort: 'createdAt-desc' },
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.accounts, { headers: { Authorization: `Bearer ${token}` } }),
		])
			.then(([merchant, reviews, accounts]: any) => {
				setMerchant(merchant.data);
				setReviews(reviews.data.data);
				setAccounts(accounts.data);
				setDataLoading(false);
			})
			.catch((_) => setDataLoading(false));
	}, [refresh]);

	const getMerchantData = (): Merchant => {
		const merchantId = Number(searchParams.get('id'));
		let merchantData = {
			merchantId: merchantId,
			fullName: merchant?.fullName ?? '',
			rating: merchant?.rating ?? 0,
			totalReviews: merchant?.totalReviews ?? 0,
			ordersCompleted: merchant?.ordersCompleted ?? 0,
			totalOrders: merchant?.totalOrders ?? 0,
			rate: (offerType === OfferType.buy ? merchant?.buyerOffer?.rate : merchant?.sellerOffer?.rate) ?? 0,
			available:
				(offerType === OfferType.buy ? merchant?.buyerOffer?.available : merchant?.sellerOffer?.available) ?? 0,
			onHold: (offerType === OfferType.buy ? merchant?.buyerOffer?.onHold : merchant?.sellerOffer?.onHold) ?? 0,
			min: (offerType === OfferType.buy ? merchant?.buyerOffer?.min : merchant?.sellerOffer?.min) ?? 0,
			max: (offerType === OfferType.buy ? merchant?.buyerOffer?.max : merchant?.sellerOffer?.max) ?? 0,
			country: { name: '', code: '' },
			countryCode: merchant?.countryCode ?? '',
			currencyCode: merchant?.currencyCode ?? '',
			currency: merchant?.currencyCode ?? '',
			paymentMethods:
				(offerType === OfferType.buy ? merchant?.buyerOffer?.paymentMethods : merchant?.sellerOffer?.paymentMethods) ??
				[],
			termsNote:
				(offerType === OfferType.buy ? merchant?.buyerOffer?.termsNote : merchant?.sellerOffer?.termsNote) ?? '',
			exchangeBaseCurrency: merchant?.exchangeBaseCurrency ?? '',
			limitReached: merchant?.limitReached ?? false,
			isCoolDown:
				(offerType === OfferType.buy ? merchant?.buyerOffer?.isCoolDown : merchant?.sellerOffer?.isCoolDown) ?? false,
			online: merchant?.online ?? false,
		};

		return merchantData;
	};

	useEffect(() => {
		if (showModal && merchantId) {
			axios
				.get(API_ENDPOINTS.getLimitReached.replace('%merchantId%', `${merchantId}`), {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((result) => {
					setIsLimitReached(result.data.limitReached);
					setIsLimitReachedModal(result.data.limitReached);
				})
				.catch((error) => console.error(error));
		}
	}, [showModal]);

	useEffect(() => {
		if (isLimitReachedModal === false) {
			setRefersh(!refresh);
		}
	}, [isLimitReachedModal]);
	return (
		<>
			<section>
				<SubHeader title={t<string>('merchantDetails')} description={t<string>('merchantDetailsDescription')} />
				<div className='single-merchant'>
					{dataLoading ? (
						<>
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
						</>
					) : (
						<React.Fragment>
							<article className='header'>
								<h2 className='name-initials'>{getInitials(merchant?.fullName ?? '')}</h2>
								<h2 className='m-1'>{merchant?.fullName}</h2>
								<div>
									<span className='text-[#333333] flex items-baseline'>
										<span>
											{merchant?.ordersCompleted} {t<string>('orders')}
										</span>
										<span className='inline-block mx-2 w-[1px] h-[10px] bg-[#737E8D40]' />
										{(
											(Number(merchant?.ordersCompleted) /
												(merchant?.totalOrders === null || merchant?.totalOrders === 0
													? 1
													: Number(merchant?.totalOrders))) *
											100
										).toFixed()}
										% {t<string>('completionRate')}
									</span>
								</div>
								<div className='flex m-0'>
									<LikeFilled className='rating-icon text-[#33A867]' />
									<span className='text-[green]'>{merchant?.rating}%</span>
									<span className='inline-block mx-2 w-[1px] h-[10px] bg-[#737E8D40]' />
									<span>
										{merchant?.totalReviews} {t<string>('reviews')}
									</span>
								</div>
								<p className='text-[#333333] m-0 p-0 mb-2'>
									{merchant?.currencyCode} {t<string>('currency')}
								</p>
							</article>
							<div className='section-container w-[100%]'>
								<section className='section'>
									<div className='text-card h-[250px]'>
										<div className='flex justify-between items-baseline'>
											<h3 className='heading'>{t<string>('buyOffer')}</h3>
											{merchant?.buyerOffer && !isMerchant && (
												<Tooltip
													placement='top'
													title={
														merchant.limitReached
															? t<string>('limitReached')
															: merchant?.buyerOffer?.isCoolDown
															? t<string>('restricted')
															: ''
													}
												>
													<div
														className={[
															'px-[10px] py-[5px] rounded shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]',
															isSameCountry ? 'bg-[#33a867] text-white' : 'bg-[#DADADA] text-[#A2A2A2]',
														].join(' ')}
														style={
															merchant.limitReached || merchant?.buyerOffer?.isCoolDown
																? { opacity: '0.5' }
																: { opacity: '1', cursor: 'pointer' }
														}
														onClick={() => {
															if (!isSameCountry || merchant.limitReached || merchant?.buyerOffer?.isCoolDown) return;
															setOfferType(OfferType.buy);
															setShowModal(true);
														}}
													>
														<span className='mr-[6px] inline-block'>
															<Buy active={true} />
														</span>
														{isDev ? t<string>('buy') : t<string>('deposit')}
													</div>
												</Tooltip>
											)}
										</div>
										{merchant?.buyerOffer ? (
											<>
												<div className='text-container'>
													<span className='info'>{t<string>('price')}</span>
													<span className='content'>
														{roundedLargeNumber(merchant?.buyerOffer?.rate)?.toLocaleString()} {merchant.currencyCode}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('availability')}</span>
													<span className='content'>
														{roundedLargeNumber(merchant?.buyerOffer?.available)?.toLocaleString()}{' '}
														{merchant.exchangeBaseCurrency}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('limit')}</span>
													<span className='content'>
														{merchant?.buyerOffer?.min?.toLocaleString()}-{merchant?.buyerOffer?.max?.toLocaleString()}{' '}
														{merchant.currencyCode}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('paymentMethod')}</span>
													<span className='content flex justify-center flex-wrap'>
														{paymentMethodBuyer.map((method) => (
															<div className='mr-[5px] p-[5px]  bg-[#d9ecff] rounded-sm text-[12px] text-[#0187ff] flex mb-[5px] h-fit items-center w-max'>
																{method.methodName}
															</div>
														))}
													</span>
												</div>
											</>
										) : (
											<Text className='flex items-center m-auto text-[16px] font-[700] text-[#6d6c6c]'>
												{' '}
												{t<string>('offerNotAvailable')}
											</Text>
										)}
									</div>
									{merchant?.buyerOffer && (
										<>
											<div className='horizontal-separator' />
											<div className='text-card'>
												<h3 className='heading'>{t<string>('buyersTerm')}</h3>
												{merchant?.buyerOffer?.termsNote ? <p>{merchant?.buyerOffer?.termsNote}</p> : <DashOutlined />}
											</div>
										</>
									)}
								</section>
								<hr />
								<section className='section'>
									<div className='text-card h-[250px]'>
										<div className='flex justify-between items-baseline'>
											<h3 className='heading'>{t<string>('sellOffer')}</h3>
											{merchant?.sellerOffer && !isMerchant && (
												<Tooltip
													placement='top'
													title={
														merchant.limitReached
															? t<string>('limitReached')
															: merchant?.sellerOffer?.isCoolDown
															? t<string>('restricted')
															: ''
													}
												>
													<div
														className={[
															'px-[10px] py-[5px] rounded shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]',
															isSameCountry ? 'bg-[#f6465d] text-white' : 'bg-[#DADADA] text-[#A2A2A2]',
														].join(' ')}
														style={
															isMerchant || merchant.limitReached || merchant?.sellerOffer?.isCoolDown
																? { opacity: '0.5' }
																: { opacity: '1', cursor: 'pointer' }
														}
														onClick={() => {
															if (!isSameCountry || merchant.limitReached || merchant?.buyerOffer?.isCoolDown) return;
															setOfferType(OfferType.sell);
															setShowModal(true);
														}}
													>
														<span className='mr-[6px] inline-block'>
															<Sell active={true} />
														</span>
														{isDev ? t<string>('sell') : t<string>('withdrawal')}
													</div>
												</Tooltip>
											)}
										</div>
										{merchant?.sellerOffer ? (
											<>
												<div className='text-container'>
													<span className='info'>{t<string>('price')}</span>
													<span className='content'>
														{roundedLargeNumber(merchant?.sellerOffer?.rate)} {merchant.currencyCode}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('availability')}</span>
													<span className='content'>
														{roundedLargeNumber(merchant?.sellerOffer?.available)?.toLocaleString()}{' '}
														{merchant.exchangeBaseCurrency}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('limit')}</span>
													<span className='content'>
														{merchant?.sellerOffer?.min?.toLocaleString()}-
														{merchant?.sellerOffer?.max?.toLocaleString()} {merchant.currencyCode}
													</span>
												</div>
												<div className='text-container'>
													<span className='info'>{t<string>('paymentMethod')}</span>
													<span className='content flex justify-center flex-wrap'>
														{paymentMethodSeller.map((method) => (
															<div className='mr-[5px] p-[5px] bg-[#d9ecff] text-[12px] text-[#0187ff] flex h-fit mb-[5px] items-center w-max'>
																{method.methodName}
															</div>
														))}
													</span>
												</div>
											</>
										) : (
											<Text className='flex items-center m-auto text-[16px] font-[700] text-[#6d6c6c]'>
												{' '}
												{t<string>('offerNotAvailable')}
											</Text>
										)}
									</div>
									{merchant?.sellerOffer && (
										<>
											<div className='horizontal-separator' />
											<div className='text-card'>
												<h3 className='heading'>{t<string>('sellersTerm')}</h3>
												{merchant?.sellerOffer?.termsNote ? (
													<p>{merchant?.sellerOffer?.termsNote}</p>
												) : (
													<DashOutlined />
												)}
											</div>
										</>
									)}
								</section>
							</div>
							<div className='full-width-separator' />
							<div className='reviews-section'>
								<div className='reviews-section-header'>
									<h2 className='review-heading'>{t<string>('merchantReview')}</h2>
								</div>
								{reviews?.length > 0 ? (
									<div className='review-container'>
										<Splide
											hasTrack={false}
											options={{
												type: 'slide',
												perPage: 3,
												breakpoints: {
													1124: { perPage: 2 },
													767: { perPage: 1 },
												},
												perMove: 1,
												rewind: false,
												arrows: true,
												direction: isRTL ? 'rtl' : 'ltr',
											}}
										>
											<div className='splide__arrows'>
												<SplidePreviousButton isRTL={isRTL} />
												<SplideNextButton isRTL={isRTL} />
											</div>

											<SplideTrack>
												{reviews?.map((review, index) => (
													<SplideSlide key={index}>
														<div className='single-review-container'>
															<div className='single-review-header justify-between'>
																<div className='flex items-center'>
																	<div className='merchant-initials font-bold mx-2'>
																		{getInitials(review?.clientFirstName)}
																	</div>
																	<h4>{review?.clientFirstName}</h4>
																</div>
																{!review.isLike ? (
																	<DislikeFilled className={`h-[20px] w-[20px] text-[#F6465D]`} />
																) : (
																	<LikeFilled className={`h-[20px] w-[20px] text-[#33A867]`} />
																)}
															</div>
															<p style={{ lineBreak: 'anywhere' }}>{review?.comment}</p>
														</div>
													</SplideSlide>
												))}
											</SplideTrack>
										</Splide>
									</div>
								) : (
									<p style={{ border: '1px solid #d2eafb', backgroundColor: '#ecf6fd', padding: '10px' }}>
										{t<string>('noReviewsForThisMerchant')}
									</p>
								)}
							</div>
						</React.Fragment>
					)}
				</div>
			</section>
			{showModal && merchant && isLimitReached === false && (
				<MerchantBuyAndSellModal
					merchant={getMerchantData()}
					paymentMethods={countriesState.paymentMethods}
					accounts={accounts}
					closeModal={() => {
						setShowModal(false);
						setIsLimitReached(null);
					}}
					offerType={offerType}
					setRefersh={setRefersh}
					refersh={refresh}
				/>
			)}
			{isLimitReached && (
				<LimitCheckModal isLimitReached={isLimitReachedModal} setIsLimitReached={setIsLimitReachedModal} />
			)}
		</>
	);
};

export default SingleMerchant;
