import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { useConfigurationContext } from 'store/ConfigurationContext';

import {
	themeColor as defaultThemeColor,
	companyWebsite as defaultcompanyWebsite,
} from '../../assets/constant/fallback-config';

const Footer = () => {
	const currentYear = React.useMemo(() => new Date().getFullYear(), []);
	const { t } = useTranslation();

	// Add Tidio chat
	useEffect(() => {
		const script = document.createElement('script');
		script.src = '//code.tidio.co/e7pqnho403v8wlwk9zxepz3dpacuuji3.js';
		script.async = true;

		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		};
	}, []);

	const { configurationState } = useConfigurationContext();
	const companyWebsite =
		configurationState.find((item) => item.configKey === 'company_website')?.value ?? defaultcompanyWebsite;
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value ?? defaultThemeColor;
	return (
		<div className='footer'>
			<div className='footer-tail bg-lightBlue py-4' style={{ backgroundColor: themeColor }}>
				<div className='max-w-[1440px] mx-auto flex flex-col sm:flex-row items-center justify-between'>
					<span className='text-white text-xs lg:mb-0'>
						© {currentYear}{' '}
						<a className='text-white text-xs no-underline' href={`https://${companyWebsite}.com`} target='_blank'>
							{companyWebsite}
						</a>{' '}
						{t<string>('allRightsReserved')}
					</span>
				</div>
			</div>
			<script src='//code.tidio.co/w0i4b7fdeerfqqn4w8lwb7ahjasjnrmd.js'></script>
		</div>
	);
};

export default Footer;
