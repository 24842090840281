export enum NotificationActionType {
	NEW_ORDER = 'NEW_ORDER',
	ORDER_NEW_MESSAGE = 'ORDER_NEW_MESSAGE',
	ORDER_EXPIRY_WARNING = 'ORDER_EXPIRY_WARNING',
	ORDER_EXPIRED = 'ORDER_EXPIRED',
	ORDER_STATUS_UPDATE = 'ORDER_STATUS_UPDATE',
	OFFER_DISABLED = 'OFFER_DISABLED',
	ACCOUNT_BALANCE_LOW = 'ACCOUNT_BALANCE_LOW',
	SELL_AVAILABLE_LOW = 'SELL_AVAILABLE_LOW',
	MERCHANT_NEW_REVIEW = 'MERCHANT_NEW_REVIEW',
	USER_SUSPEND = 'USER_SUSPEND',
	USER_UNSUSPEND = 'USER_UNSUSPEND',
	USER_BLOCK = 'USER_BLOCK',
	USER_UNBLOCK = 'USER_UNBLOCK',
	EXCHANGE_RATE_UPDATE = 'EXCHANGE_RATE_UPDATE',
	ORDER_REJECTION_WARNING = 'ORDER_REJECTION_WARNING',
}
