import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import Logo from '../../assets/images/logo.png';
import { getMenuItems } from '../../helpers/menuItems';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMainContext } from '../../store/MainContext';
import { useConfigurationContext } from '../../store/ConfigurationContext';
import { themeColor as defaultThemeColor } from '../../assets/constant/fallback-config';

import NavBarIcons from './NavBarIcons';

const Navbar = () => {
	const { t } = useTranslation();
	const { mainState, setMainState } = useMainContext();
	const { configurationState } = useConfigurationContext();

	const isLoggedIn = React.useMemo(() => mainState.isLoggedIn, [mainState.isLoggedIn]);
	const isMerchant = React.useMemo(() => mainState.isMerchant, [mainState.isMerchant]);
	const menuItems = React.useMemo(() => getMenuItems(isLoggedIn, isMerchant), [isLoggedIn, isMerchant]);
	const location = useLocation();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value ?? defaultThemeColor;

	return (
		<div style={{ backgroundColor: themeColor }}>
			<div className='max-w-[1440px] mx-auto px-[15px] md:px-[75px] pb-3 pt-2 flex flex-col md:flex-row items-center justify-between'>
				{/* Logo and Menu Items */}
				<div className='flex flex-col md:flex-row items-center justify-between w-full md:w-auto'>
					<div className='flex justify-between xss:w-full sm:w-auto xss:mb-2 sm:mb-0'>
						{process.env.REACT_APP_LOGO === 'false' ? (
							<div className='h-[40px]' />
						) : (
							<>
								{!mainState.isLoggedIn ? (
									<Link to='/sign-in'>
										<img src={Logo} alt='logo' className='xss:w-28 sm:w-32 mx-3 md:mx-0 md:ml-3' />
									</Link>
								) : (
									<Link to='/merchants'>
										<img src={Logo} alt='logo' className='xss:w-28 sm:w-32 mx-3 md:mx-0 md:ml-3' />
									</Link>
								)}
							</>
						)}
						{windowWidth < 500 && <NavBarIcons />}
					</div>
					{isLoggedIn && (
						<div className='flex md:items-center md:ml-3 w-[101%] xss:justify-around'>
							{menuItems.map((i: any) => (
								<Link
									className={`text-white text-sm font-medium opacity-95 md:mx-5 hover:opacity-70 hover:text-white transition-all duration-300 ${
										location.pathname === `/${i.key}` ? 'underline' : 'no-underline'
									}`}
									to={i.key}
									key={i.key}
								>
									{t(i.label)}
								</Link>
							))}
							<a
								href='https://my.inzo.co/login'
								target='_blank'
								rel='noreferrer'
								className={`text-white text-sm font-medium opacity-95 flex justify-center md:mx-5 hover:opacity-70 hover:text-white transition-all duration-300 no-underline`}
							>
								{t('tradersRoom')}
							</a>
						</div>
					)}
				</div>
				{windowWidth >= 500 && <NavBarIcons />}
			</div>
		</div>
	);
};

export default Navbar;
