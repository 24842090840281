import { Alert, Skeleton, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import './OrderDetails.scss';
import { CopyFilled } from '@ant-design/icons';

type OrderDetailsProps = {
	unitPrice: string;
	quantity: string;
	amount: string;
	note: string;
};

function OrderDetails({ unitPrice, quantity, amount, note }: OrderDetailsProps) {
	const { t } = useTranslation();

	const copyMyText = (value: string) => {
		let textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};

	return (
		<div className='order-details-wrapper'>
			{note ? <Alert message={t<string>('note')} description={note} type='info' /> : null}
			<h3 className='order-details-title'>{t<string>('orderDetails')}</h3>
			{amount !== 'undefined' ? (
				<>
					<div className='details-row'>
						<p>{t<string>('unitPrice')}</p>
						<div className='flex'>
							<p className='value' id='unitprice'>
								{unitPrice}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('unitprice')} />
						</div>
					</div>
					<div className='details-row'>
						<p>{t<string>('quantity')}</p>
						<div className='flex'>
							<p className='value' id='quantity1'>
								{quantity}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('quantity1')} />
						</div>
					</div>
					<div className='details-row'>
						<p>{t<string>('amount')}</p>
						<div className='flex'>
							<p className='value' id='amount1'>
								{amount}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('amount1')} />
						</div>
					</div>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
		</div>
	);
}

export default OrderDetails;
