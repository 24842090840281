import { OrderFeedbackType } from '../../../types/OrderFeedbackType';
import { ActionType } from '../../../types/ActionType';
import { OrderStateType } from '../../../types/OrderStateType';
import { OrderType } from '../../../types/OrderType';
import ActionsCorner from './ActionsCorner/ActionsCorner';
import './BodyDetails.scss';
import OrderDetails from './OrderDetails/OrderDetails';
import PaymentMethodDetails from './PaymentMethodDetails/PaymentMethodDetails';
import { useState, useEffect } from 'react';
import ResponsiveBodyDetails from './ResponsiveBodyDetails';
import Stomp from 'stompjs';

type BodyDetailsProps = {
	unitPrice: string;
	quantity: string;
	amount: string;
	feedback: OrderFeedbackType;
	paymentMehodId: number;
	accountNNumber: string;
	orderState: OrderStateType;
	orderNumber: string;
	actions: ActionType[];
	orderType: OrderType;
	merchantId: string;
	userName: string;
	note: string;
	canReview: boolean;
	isActionsDisabled: boolean;
	isActionsHidden: boolean;
	triggerAction: (action: ActionType, reasons?: string, otp?: string) => void;
	getOrderDetails: () => void;
	paymentProof: boolean;
	paymentProofFileId: string;
	paymentProofFileLabel: string;
	otpVlaid: { value: boolean };
	loading: boolean;
	stompClient: Stomp.Client | null;
	accountGroup: string;
};

function BodyDetails({
	orderState,
	unitPrice,
	quantity,
	amount,
	feedback,
	paymentMehodId,
	accountNNumber,
	accountGroup,
	orderNumber,
	actions,
	orderType,
	merchantId,
	userName,
	note,
	isActionsDisabled,
	isActionsHidden,
	canReview,
	triggerAction,
	getOrderDetails,
	paymentProof,
	paymentProofFileId,
	paymentProofFileLabel,
	otpVlaid,
	loading,
	stompClient,
}: BodyDetailsProps) {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className='body-details-wrapper'>
			{windowWidth >= 500 && (
				<>
					<OrderDetails unitPrice={unitPrice} quantity={quantity} amount={amount} note={note} />
					<PaymentMethodDetails
						paymentMehodId={paymentMehodId}
						accountNNumber={accountNNumber}
						accountGroup={accountGroup}
						amount={amount}
						orderType={orderType}
						paymentProof={paymentProof}
						paymentProofFileId={paymentProofFileId}
						paymentProofFileLabel={paymentProofFileLabel}
						orderNumber={orderNumber}
					/>
				</>
			)}
			{windowWidth < 500 && (
				<ResponsiveBodyDetails
					unitPrice={unitPrice}
					quantity={quantity}
					note={note}
					paymentMehodId={paymentMehodId}
					accountNumber={accountNNumber}
					accountGroup={accountGroup}
					amount={amount}
					orderType={orderType}
					paymentProof={paymentProof}
					paymentProofFileId={paymentProofFileId}
					paymentProofFileLabel={paymentProofFileLabel}
					orderNumber={orderNumber}
				/>
			)}
			{isActionsHidden ? null : (
				<ActionsCorner
					orderState={orderState}
					orderType={orderType}
					feedback={feedback}
					actions={actions}
					orderNumber={orderNumber}
					merchantId={merchantId}
					quantity={quantity}
					amount={amount}
					userName={userName}
					isActionsDisabled={isActionsDisabled}
					canReview={canReview}
					triggerAction={triggerAction}
					getOrderDetails={getOrderDetails}
					otpVlaid={otpVlaid}
					loading={loading}
					stompClient={stompClient}
				/>
			)}
		</div>
	);
}

export default BodyDetails;
