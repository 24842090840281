import './SelectAccount.scss';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const SelectAccount = ({ accounts, selectedAccount, setSelectedAccount }: any) => {
	const { t } = useTranslation();

	return (
		<div>
			<p className='select-account-label'>{t<string>('account')}</p>
			<Select
				className='modal-payment-methods'
				defaultValue={''}
				value={selectedAccount}
				onChange={(e: string) => setSelectedAccount(e)}
			>
				{accounts.map((item: any, index: any) => (
					<Select.Option key={index} value={item.number}>
						{item.accountGroup} | {item.number} | {item.tradingPlatform} | {item.balance}USD
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

export default SelectAccount;
