import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileUpload from './UploadFile';
import axios from 'axios';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { getUserInfo } from 'helpers/localStorageHandler';

export function PaymentCompletedDialog({
	showPaymentCompletedDialog,
	setShowPaymentCompletedDialog,
	handelPaymentCompleted,
	orderNumber,
	getOrderDetails,
	loading,
}: {
	showPaymentCompletedDialog: boolean;
	setShowPaymentCompletedDialog: (value: boolean) => void;
	handelPaymentCompleted: () => void;
	orderNumber: string;
	getOrderDetails: () => void;
	loading: boolean;
}) {
	const { t } = useTranslation();
	const [isPaymentCompletedOrderChecked, setIsPaymentCompletedOrderChecked] = useState(false);
	const [isFileRequired, setIsFileRequired] = useState(false);
	const [showValidation, setShowValidation] = useState(false);
	const [fileSelected, setFileSelected] = useState();
	const token = getUserInfo()?.token;
	useEffect(() => {
		if (showPaymentCompletedDialog) {
			axios
				.get(API_ENDPOINTS.getConfiguration.replace('%configKey%', 'payment_proof_enable'), {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then((response) => {
					const paymentProofEnable = response.data.find((item: any) => item.configKey === 'payment_proof_enable');
					setIsFileRequired(paymentProofEnable?.configValue === '1');
				})
				.catch((err) => console.error(err));
		}
	}, [token]);

	const onFileSelected = (value: any) => {
		if (value) {
			setIsFileRequired(false);
			setShowValidation(false);
			setFileSelected(value);
		}
	};
	const onConfirm = () => {
		if (fileSelected) {
			const formData = new FormData();
			formData.append('file', fileSelected);
			axios
				.post(API_ENDPOINTS.uploadFilePaymentProof.replace('%orderNumber%', orderNumber), formData, {
					headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
				})
				.then((result) => handelPaymentCompleted())
				.catch((error) => getOrderDetails());
		} else {
			handelPaymentCompleted();
		}
	};
	return (
		<Modal
			onCancel={() => setShowPaymentCompletedDialog(false)}
			destroyOnClose={true}
			open={showPaymentCompletedDialog}
			footer={null}
			title={
				<div>
					<InfoCircleOutlined style={{ fontSize: '40px', textAlign: 'center', margin: '5px auto', display: 'block' }} />
					<h4 className='mt-2'>{t<string>('completeYourPayment')}</h4>
				</div>
			}
		>
			<ul>
				<li>{t<string>('paymentCompletedNote1')}</li>
				<li>{t<string>('paymentCompletedNote2')}</li>
			</ul>
			{showValidation && <span style={{ color: 'red', marginRight: '4px' }}>{t<string>('fileUploadRequired')}</span>}
			<FileUpload onFileSelected={onFileSelected} />
			<Checkbox
				checked={isPaymentCompletedOrderChecked}
				onChange={(e) => setIsPaymentCompletedOrderChecked(e.target.checked)}
				style={{ marginBottom: '20px' }}
			>
				{t<string>('sucessfullyCompletedPaymentToSeller')}
			</Checkbox>
			<div className='flex justify-end'>
				<button
					className='bg-[#ffffff] border-[black] border-[1px] rounded w-[100px] h-[35px]'
					onClick={() => {
						setShowPaymentCompletedDialog(false);
						setIsPaymentCompletedOrderChecked(false);
					}}
				>
					{t<string>('cancel')}
				</button>
				<Spin spinning={loading}>
					<button
						disabled={!isPaymentCompletedOrderChecked}
						className='form-btn'
						style={{ cursor: 'pointer', margin: '0 10px', maxWidth: '100px', height: '36px', fontWeight: 400 }}
						onClick={() => {
							if (isFileRequired) {
								return setShowValidation(true);
							}
							setShowPaymentCompletedDialog(false);
							setIsPaymentCompletedOrderChecked(false);
							onConfirm();
						}}
					>
						{t<string>('confirm')}
					</button>
				</Spin>
			</div>
		</Modal>
	);
}
