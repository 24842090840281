import { Checkbox, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurationContext } from 'store/ConfigurationContext';
import { CompanyName } from '../../assets/constant/fallback-config';

export function TermsAndCondtions({
	showTerms,
	setShowTerms,
	isAccetingTerms,
}: {
	showTerms: boolean;
	setShowTerms: (value: boolean) => void;
	isAccetingTerms: (value: boolean) => void;
}) {
	const { t } = useTranslation();
	const { configurationState } = useConfigurationContext();
	const company_name = configurationState.find((item) => item.configKey === 'Company_Name')?.value ?? CompanyName;
	const [acceptTerms, setAccetTerms] = React.useState(false);
	return (
		<Modal onCancel={() => setShowTerms(false)} open={showTerms} width={700} footer={null}>
			<div className='flex justify-start items-center text-2xl justify-center'>
				<p className='ps-[10px] text-[18px] font-bold text-[#00afff] my-0 underline'>
					{t<string>('termsAndConditionsTitle', { company_name })}
				</p>
			</div>

			<div className='content mb-2'>
				<p className='my-0 text-black font-bold'>{t<string>('RegistrationAndqualification')}:</p>
				<p className='my-0 text-[#333]'>-{t<string>('RegistrationAndqualification1', { company_name })}</p>
				<p className='my-0 text-[#333]'>-{t<string>('RegistrationAndqualification2')}</p>
				<p className='my-0 text-[#333]'>-{t<string>('RegistrationAndqualification3')}</p>
				<p className='my-0 text-black font-bold'>{t<string>('TransactionsAndfees')}:</p>
				<p className='my-0 text-[#333]'>-{t<string>('TransactionsAndfees1', { company_name })}</p>
				<p className='my-0 text-[#333]'>-{t<string>('TransactionsAndfees2')}</p>
				<p className='my-0 text-black font-bold'>{t<string>('CommitmentTosafety')}:</p>
				<p className='my-0 text-[#333]'>-{t<string>('CommitmentTosafety1')}</p>
				<p className='my-0 text-[#333]'>-{t<string>('CommitmentTosafety2')}</p>
				<p className='my-0 text-black font-bold'>{t<string>('Compliancewithlawsandregulations')}:</p>
				<p className='my-0 text-[#333]'>-{t<string>('Compliancewithlawsandregulations1')}</p>
				<p className='my-0 text-black font-bold'>{t<string>('Positiveinteractionwithcustomers')}:</p>
				<p className='my-0 text-[#333]'>-{t<string>('Positiveinteractionwithcustomers1')}</p>
				<p className='my-0 text-black font-bold'>{t<string>('Terminationofservice')}:</p>
				<p className='my-0 text-[#333]'>-{t<string>('Terminationofservice1')}</p>
			</div>
			<Checkbox
				checked={acceptTerms}
				onChange={(e) => setAccetTerms(e.target.checked)}
				style={{ marginBottom: '20px', direction: 'ltr' }}
			>
				{t<string>('acceptTerms')}
			</Checkbox>
			<div>
				<button
					className='bg-[#0183ff] border-none text-[white] w-[110px] h-[56px]  rounded mx-2'
					style={{
						cursor: 'pointer',
						margin: '0 auto',
						maxWidth: '100px',
						height: '36px',
						fontWeight: 400,
						opacity: acceptTerms ? '1' : '.5',
					}}
					disabled={!acceptTerms}
					onClick={() => {
						setShowTerms(false);
						isAccetingTerms(acceptTerms);
					}}
				>
					{t<string>('aggree')}
				</button>
				<button
					className='bg-[white] border-[black] w-[100px] h-[36px] mx-2 rounded'
					onClick={() => setShowTerms(false)}
				>
					{t<string>('cancel')}
				</button>
			</div>
		</Modal>
	);
}
