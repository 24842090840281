import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from 'assets/api/endpoints';
import { AnnouncementState } from 'models/annoncement-state';
import dayjs from 'dayjs';
import { getUserLanguageInCookie } from 'helpers/cookiesHandler';
const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';
export default function AnnoncemetBanner() {
	const [annoncements, setAnnocements] = useState<AnnouncementState>([]);
	const location = useLocation();
	const { t } = useTranslation();
	useEffect(() => {
		if (!location.pathname.includes('sign-in')) {
			axios.get(API_ENDPOINTS.getAnnoncement).then((result) => {
				setAnnocements(result.data);
			});
		}

		if (location.pathname.includes('sign-in')) setAnnocements([]);
	}, [location.pathname]);

	if (!annoncements.length) {
		return null;
	}
	let lang = getUserLanguageInCookie() ? getUserLanguageInCookie() : 'en';
	return (
		<div>
			<Splide
				options={{
					type: 'slide',
					perPage: 1,
					pagination: false,
					breakpoints: {
						1124: { perPage: 1 },
						767: { perPage: 1 },
					},
					perMove: 1,
					rewind: false,
					arrows: true,
				}}
			>
				{annoncements.map((annoncement, index) => (
					<SplideSlide key={index} style={{ display: 'flex', justifyContent: 'center' }}>
						<p className='xss:text-xs md:text-sm flex items-center m-2 mx-10'>
							<InfoCircleOutlined style={{ textAlign: 'center', margin: '0 5px', display: 'flex' }} />
							{t<string>('annoncement', {
								from: dayjs(annoncement.startDate).locale(lang).format(DATE_TIME_FORMAT),
								to: dayjs(annoncement.endDate).locale(lang).format(DATE_TIME_FORMAT),
							})}
						</p>
					</SplideSlide>
				))}
			</Splide>
		</div>
	);
}
