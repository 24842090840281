import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { CompanyName } from '../assets/constant/fallback-config';
import inzo from '../assets/logos/inzo.png';
import webbats from '../assets/logos/webbats.png';
import { useConfigurationContext } from 'store/ConfigurationContext';

function MainWrapper({ children }: { children: React.ReactNode }) {
	const logo = process.env.REACT_APP_ICON || true;
	const { configurationState } = useConfigurationContext();
	const title = configurationState.find((item) => item.configKey === 'Company_Name')?.value ?? CompanyName;
	const getLogo = () => {
		return logo === 'webbats' ? webbats : inzo;
	};
	return (
		<>
			<Helmet>
				<title>{title} P2P</title>
				<link rel='icon' type='image/x-icon' href={getLogo()} />
			</Helmet>
			{children}
		</>
	);
}

export default MainWrapper;
