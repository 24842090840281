import { LanguagePhrases } from '../models/LanguagePhrases';

export const arabicPhrases: LanguagePhrases = {
	yesterday: 'أمس',
	daysAgo: 'منذ ايام',
	today: 'اليوم',
	signIn: 'تسجيل الدخول',
	home: 'الرئيسية',
	signinTitle: 'قم بتسجيل الدخول إلى حساب الدفع الخاص بك',
	signinDesc: 'قم بتسجيل الدخول باستخدام بيانات الاعتماد الخاصة ببوابة العميل',
	email: 'البريد الإلكتروني',
	password: 'كلمة المرور',
	forgotPassword: 'نسيت كلمة المرور',
	emailRequired: 'يجب ألا يكون حقل البريد الإلكتروني فارغًا!',
	passwordRequired: 'يجب ألا يكون حقل كلمة المرور فارغًا!',
	somethingWrong: 'حدث خطأ ما!',
	emailMsg: 'يرجى التأكد من صحة عنوان البريد الإلكتروني الخاص بك!',
	passwordMsg:
		'تأكد من أن كلمة المرور لديك تحتوي على الأقل 8 أحرف ، وتتضمن حرفًا كبيرًا وحرفًا صغيرًا وعلامة واحدة على الأقل.',
	becomeMerchant: 'كن تاجرًا',
	yourAccountIsInactive: 'حسابك غير نشط. يرجى الاتصال بالشركة لتنشيطه ',
	regularUserHome: 'الصفحة الرئيسية للمستخدم العادي',
	merchantHome: ' الرئيسية',
	merchantsOffer: 'عروض التجار',
	notificationsHeading: 'الإشعارات',
	searchInMerchantsTable: 'البحث في جدول التجار',
	problemLoadingListOfMerchants: 'توجد مشكلة في تحميل قائمة التجار',
	name: 'اسم',
	rating: 'تقييم',
	totalReview: 'جميع التقييمات',
	ordersCompleted: 'الطلبات المنجزة',
	price: 'السعر',
	available: 'متاح',
	minLimit: 'الحد الأدنى',
	maxLimit: 'الحد الأقصى',
	buy: 'شراء',
	sell: 'بيع',
	accountVerification: 'تأكيد الحساب',
	problemLoadingMerchant: 'هناك بعض المشاكل في تحميل معلومات التاجر',
	merchantInfo: 'معلومات التاجر',
	wantToBuy: 'أريد ان اشتري',
	wantToSell: 'أريد أن أبيع',
	amountIsRequired: 'المبلغ مطلوب',
	willReceive: 'سوف استلم',
	willSend: 'سوف أرسل',
	paymentMethod: 'طريقة الدفع ',
	paymentMethodIsRequired: 'طريقة الدفع مطلوبة',
	valueInRangeError: 'يجب أن يكون المبلغ في هذا النطاق',
	merchants: 'التجار',
	countryCurrency: 'الدولة / العملة',
	paymentMethods: 'طرق الدفع',
	myDetails: 'تفاصيلي',
	changePassword: 'تغيير كلمة المرور',
	myDetailsForm: 'نموذج التفاصيل الخاصة بي',
	myProfile: 'ملفي الشخصي',
	firstName: 'الاسم الأول',
	surname: 'اسم العائلة',
	phone: 'هاتف',
	address: 'عنوان',
	city: 'مدينة',
	zip: 'zip',
	state: 'ولاية',
	country: 'دولة',
	registrationDate: 'تاريخ التسجيل',
	newPassword: 'كلمة المرور الجديدة',
	currentPassword: 'كلمة المرور الحالية',
	confirmPassword: 'تأكيد كلمة المرور',
	updatePassword: 'تحديث كلمة السر',
	changePasswordForm: 'نموذج تغيير كلمة المرور',
	yourPasswordGotUpdated: 'تم تحديث كلمة المرور',
	somethingWentWrongWithUpdatingPassword: 'حدث خطأ في تحديث كلمة المرور',
	pleaseMakeSurePasswordIsValid:
		'يرجى التأكد من أن كلمة مرورك تتضمن على الأقل حرفًا كبيرًا وحرفًا صغيرًا وحرفًا خاصًا وأن يكون طول كلمة المرور 8 على الأقل وباللغة الإنكليزية',
	twoPasswordsDoNotMatch: 'يجب أن تكون كلمتا المرور متطابقتين',
	myOffers: 'العروض',
	yourSellerFormGotUpdated: 'تم تحديث نموذج البائع الخاص بك',
	somethingWentWrongWithUpdatingSellerForm: 'حدث خطأ في تحديث نموذج البائع',
	yourBuyerFormGotUpdated: 'تم تحديث نموذج المشتري الخاص بك',
	somethingWentWrongWithUpdatingBuyerForm: 'حدث خطأ في تحديث نموذج المشتري',
	sellOffer: 'عرض البيع',
	profitRatio: 'نسبة الربح',
	profitRatioIsRequired: 'نسبة الربح مطلوبة',
	profitRatioInRangeError: 'يجب أن تكون نسبة الربح بين',
	atleastSelectOneMethod: 'الرجاء تحديد طريقة دفع واحدة على الأقل',
	maximumAmount: 'الحد الأقصى للمبلغ',
	valueMustBePositive: 'يجب أن تكون القيمة موجبة',
	mustBeGreaterThanMinValue: 'يجب أن تكون القيمة أكبر من الحد الأدنى للقيمة',
	minimumAmount: 'الحد الأدنى للمبلغ',
	mustBeLessThanMaxValue: 'يجب أن تكون القيمة أقل من الحد الأقصى للقيمة',
	visibleForUsers: 'مرئي للمستخدمين',
	no: 'لا',
	yes: 'نعم',
	update: 'تحديث',
	buyOffer: 'عرض الشراء',
	logout: 'تسجيل خروج',
	privacyPolicy: 'سياسة الخصوصية',
	companies: 'شركات',
	news: 'أخبار',
	termsOfUse: 'شروط الاستخدام',
	merchantsOfferSubtitle: 'اكتشف صفقات P2P بدون رسوم واختر وسيلة الدفع المفضلة على منصتنا اليوم!',
	notificationsSubtitle: 'تمكينك مع التنبيهات في الوقت المناسب! ابق على اتصال، ابق على اطلاع.',
	limit: 'الحد',
	countries: 'الدول',
	min: 'الحد الأدنى',
	max: 'ألحد ألاقصى',
	all: 'الكل',
	merchantProfile: 'ملف التاجر',
	availableLimit: 'متاح / محدود',
	trade: 'تجارة',
	fees: 'رسوم',
	reviews: 'التقيمات',
	buyers: 'المشترون',
	sellers: 'البائعون',
	termsAndConditions: 'الشروط والاحكام',
	unitPrice: 'سعر الوحدة',
	youSale: 'أنت تبيع',
	youReceive: 'انت تستقبل',
	selectPaymentMethods: 'حدد طرق الدفع',
	account: 'حسابات',
	note: 'ملاحظة',
	pleaseWriteYourNote: 'يرجى كتابة ملاحظتك',
	certainPaymentMethodsMayHaveFees: 'قد يكون لبعض طرق الدفع رسوم وحدود يومية يحددها مزود الدفع.',
	youPay: 'انت تدفع',
	orders: 'الطلبات',
	welcomeBack: 'مرحبًا بعودتك',
	loginToYourAccount: 'تسجيل الدخول إلى حسابك',
	pleaseEnterYourEmail: 'رجاءا أدخل بريدك الإلكتروني',
	enterPassword: 'أدخل كلمة المرور',
	notRegistered: 'غير مسجل',
	or: 'أو',
	createAnAccount: 'إنشاء حساب',
	readOur: 'اقرأ',
	signinWarningNote:
		'تحذير: الوصول غير المصرح به إلى هذا النظام ممنوع وسيتم مقاضاته بموجب القانون. من خلال الوصول إلى هذا النظام ، فإنك توافق على أنه قد تتم مراقبة أفعالك في حالة الاشتباه في الاستخدام غير المصرح به',
	allRightsReserved: 'كل الحقوق محفوظة',
	availableIsRequired: 'الرصيد المتوفر ',
	somethingWentWrongVerification: 'حدث خطأ ما، يرجى التأكد من أن مستنداتك قد تم قبولها وأن حسابك أصبح مفعلاً',
	myOrders: 'صفقاتي',
	myOrderSubtitle: 'التاريخ يعيد نفسه: إعادة الطلب بسهولة، في كل مرة!',
	calculatedPrice: 'السعر النهائي',
	status: 'حاله الطلب',
	dateRange: 'نطاق التاريخ',
	sent: 'مُرسَل',
	recieved: 'مُستَلَم',
	active: 'نشِط',
	pending: 'قيد الانتظار',
	completed: 'مُكتَمِل',
	cancel: 'إلغاء',
	inDispute: 'قيد التنازُع',
	id: 'المعرف',
	quantity: 'الكمية',
	amount: 'المبلغ',
	dateAndTime: 'التاريخ والوقت',
	searchOrder: 'البحث في الطلبات',
	transferPaymentToSeller: 'تحويل الدفعه للبائع',
	pendingSellerToReleasePayment: 'البائع معلق للإفراج عن الدفع',
	orderNumber: 'رقم الطلب',
	timeCreated: 'تاريخ بدء الطلب',
	activeOrder: 'أمر نشط',
	pendingOrder: 'طلب معلق',
	completedOrder: 'الطلب مكتمل',
	cancelledOrder: 'الطلب ملغي',
	buyFrom: 'يشترى من',
	buyUSDFrom: 'شراء دولار أمريكي من',
	buyIQDFrom: 'اشتري دينار عراقي من',
	orderCreatedConfirmation: 'تم إنشاء الأمر. يرجى انتظار تأكيد النظام',
	orderWaitingMessage: 'في انتظار تأكيد الدفع من البائع لإصدار الطلب ، اكتمل 95٪ من طلب هذا البائع في غضون 3 دقائق',
	orderCompletedMessage:
		'تم وضع علامة على الطلب على أنه مكتمل بنجاح. إذا كانت لديك أي مشكلة في الدفع ، فاتصل بالدعم لحل المشكلة',
	youSuccessfullyBought: 'لقد اشتريت بنجاح',
	IQDFrom: 'دينار عراقي من',
	thisOrderHasBeenCancelled: 'تم الغاء هذا الطلب',
	thisOrderIsInDispute: 'هذا الطلب قيد النزاع',
	orderAmount: 'قيمة الطلب',
	youPaid: 'لقد دفعت',
	accountName: 'إسم الحساب',
	orderDetails: 'تفاصيل الطلب',
	payWithZenCash: 'ادفع مع ZenCash',
	selectPaymentCompleted: 'حدد اكتمل الدفع لإبلاغ البائع',
	paymentCompletedNotify: 'اكتمل الدفع ، أبلغ البائع',
	paymentReceived: 'تم استلام الدفعة',
	BackToHome: 'العودة إلى الصفحة الرئيسية',
	reorder: 'إعادة الطلب',
	contactSupport: 'اتصل بالدعم',
	cancelOrder: 'الغاء الطلب',
	completeYourPayment: 'إتمام الدفع الخاص بك؟',
	note1: 'يُرجى تأكيد أنك قد نجحت في تحويل الأموال إلى البائع',
	note2: 'ملاحظة: لا تتابع إذا لم تكمل الدفع / قد يؤدي هذا إلى تعليق المبلغ',
	rateTheMerchant: 'قيم التاجر',
	orderS: 'طلبات',
	lastSeen: 'اخر ظهور',
	ago: 'منذ',
	yourName: 'اسمك',
	yourEmail: 'بريدك الالكتروني',
	yourPhone: 'هاتفك',
	subject: 'موضوع',
	message: 'رسالة',
	nameIsRequired: 'مطلوب اسم',
	emailIsRequired: 'البريد الالكتروني مطلوب',
	phoneIsRequired: 'الهاتف مطلوب',
	subjectIsRequired: 'الموضوع مطلوب',
	messageIsRequired: 'الرسالة مطلوبة',
	sendMessage: 'أرسل رسالة',
	bestSellers: 'أفضل البائعين',
	maxOrders: 'الحد الاعلى للطلبات',
	minOrders: 'الحد الأقل للطلبات',
	importantNote: 'ملاحظة هامة',
	confirm: 'تأكيد',
	confirmContactingMerchantInChat:
		'يرجى التأكد من الاتصال بالتاجر قبل إجراء أي دفعة. انزو لن تكون  مسؤولة عن أي نوع من الخسارة.',
	myAccounts: 'حساباتي',
	myAccountsSubtitle: 'احصل على نظرة واضحة على محفظتك ، بما في ذلك الأرصدة وتفاصيل الحساب.',
	tradingPlatform: 'منصة التداول',
	accountType: 'نوع الحساب',
	accountGroup: 'مجموعة الحساب',
	accountNumber: 'رقم الحساب',
	balance: 'الرصيد',
	onHoldBalance: 'الأرصدة المعلقة',
	client: 'عميل',
	emailUs: 'أرسل لنا بريدًا إلكترونيًا',
	merchant: 'تاجر',
	writeMessage: 'اكتب رسالة',
	availability: 'التوفر',
	buyersTerm: 'شروط وأحكام المشتري',
	sellersTerm: 'شروط وأحكام البائع',
	bankDeposit: 'الإيداع البنكي فقط',
	accHolder: 'صاحب الحساب الأصلي',
	thirdParty: 'غير مقبول الدفع من طرف ثالث',
	wedFri: 'الأربعاء-الجمعة',
	merchantReview: 'تقيم التاجر',
	apiNotFound: 'لم يتم العثور على واجهة برمجة التطبيقات',
	unableToAuthenticateTheUser: 'جلسة غير صالحة أو منتهية الصلاحية',
	somethingWentWrong: 'هناك خطأ ما',
	constraintViolated: 'تم انتهاك القيد',
	recordIsLinked: 'السجل مرتبط',
	parametersCannotBeNull: 'لا يمكن أن تكون المعلمات فارغة',
	methodArgumentsAreInvalid: 'وسيطات الأسلوب غير صالحة',
	fileUploadingError: 'خطأ في تحميل الملف',
	parameterIsNotValid: 'معلمة الطلب غير صالحة',
	requiredParametersAreMissing: 'المعلمات المطلوبة مفقودة',
	pleaseEnterValidEmailAddress: 'يرجى إدخال عنوان بريد إلكتروني صالح',
	passwordDoesNotMatchRequirements:
		'كلمة المرور لا تتطابق مع المتطلبات: الحد الأدنى 8 والحد الأقصى 16 حرفًا، وتتضمن على الأقل حرفًا كبيرًا واحدًا وحرفًا صغيرًا واحدًا ورقمًا واحدًا وحرفًا خاصًا واحدًا',
	invalidMerchantOfferRequest: 'طلب عرض التاجر غير صالح',
	invalidTradingAccountGroup: 'مجموعة حسابات التداول غير صالحة',
	countryIsNotAllowed: 'البلد غير مسموح به في نظام هذا النضام ',
	orderNumberProvidedIsInvalid: 'معرف الطلب المقدم غير صالح',
	orderIsClosed: 'الطلب مغلق',
	actionNotApplicable: 'الإجراء غير قابل للتطبيق',
	errorOccurredWhileProcessingYourRequest: 'حدث خطأ أثناء معالجة طلبك',
	problemOccurredWhileLoadingRecords: 'حدثت مشكلة أثناء تحميل السجلات',
	problemOccurredWhileLoadingRecord: 'حدثت مشكلة أثناء تحميل السجل',
	problemOccurredWhileAddingRecord: 'حدثت مشكلة أثناء إضافة السجل',
	problemOccurredWhileUpdatingRecord: 'حدثت مشكلة أثناء تحديث السجل',
	problemOccurredWhileDeletingRecord: 'حدثت مشكلة أثناء حذف السجل',
	recordAlreadyExists: 'السجل موجود من قبل',
	noRecordFound: 'لا يوجد سجلات',
	accessDeniedMissingKyc: 'تم رفض الوصول بسبب فقدان الوثائق',
	invalidCredentials: 'بيانات الاعتماد غير صالحة',
	invalidPaymentMethod: 'طريقة الدفع غير صالحة',
	invalidOfferType: 'نوع العرض غير صالح',
	orderIsInProgress: 'الطلب قيد المعالجة',
	orderReviewIsAlreadyPresent: 'تقيم الطلب موجود بالفعل',
	orderIsClosedMoreThanWeekAgo: 'الطلب مغلق منذ أكثر من أسبوع',
	userIsInactive: 'المستخدم غير نشط',
	userCountryNotAllowed: 'بلد المستخدم غير مسموح به',
	orderIsExpiredOrClosed: 'انتهت صلاحية الطلب أو تم إغلاقه',
	invalidOrderAction: 'إجراء أمر غير صالح',
	onlyOneOrderAllowedAtTime: 'يُسمح بطلب واحد فقط في كل مرة',
	invalidTradingAccount: 'حساب التداول غير صالح',
	merchantBelongsToDifferentCountry: 'لا يمكن التجارة! التاجر ينتمي إلى بلد مختلف',
	orderAmountIsMoreThanAvailableBalance: 'مبلغ الطلب أكثر من الرصيد المتاح!',
	merchantOfferNotAvailable: 'عرض التاجر غير متوفر!',
	amountDoesNotSatisfyAvailableLimit: 'المبلغ لا يلبي الحد المتاح!',
	iraq: 'العراق',
	lebanon: 'لبنان',
	saudi: 'المملكة السعودية',
	egypt: 'مصر',
	jordan: 'الأردن',
	requestWasSentSuccessfully: 'تم ارسال الطلب بنجاح',
	noReviewsForThisMerchant: 'لا توجد تعليقات لدى هذا التاجر',
	problemLoadingListOfOrders: 'حدثت مشكلة أثناء تحميل قائمة الطلبات',
	new: 'جديد',
	cancelled: 'ملغي',
	transferred: 'تم الإرسال',
	sellerConfirmed: 'تم تأكيد البائع',
	cancellationRequest: 'طلب بالإلغاء',
	timedOut: 'نافذ التوقيت',
	appealed: 'يوجد شكوى',
	rejected: 'مرفوض',
	closed: 'مغلق',
	myOffersSubtitle: 'هذه هي العروض التي سيتم تقديمها لعملائك',
	merchantDetails: 'تفاصيل التاجر',
	merchantDetailsDescription:
		'استكشاف أفضل سمات التجار وآرائهم، تقديم تحليلات قيمة لتعزيز تجربتك في التداول بنظام التجارة الذي توفره لك انزو',
	onlyClientsCanStartOrders: 'يمكن للعملاء فقط بدء الطلبات',
	maxValueRange: 'يجب أن تكون القيمة القصوى بين 1 و 1000000.',
	noteMinLength: 'يجب أن يتجاوز طول الملاحظة 20 حرفًا',
	noteMaxLength: 'يجب أن يكون طول الملاحظة أقل من 1000 حرف',
	cantOpenOrderNows: 'لا يمكنك بدء طلب جديد لأن لديك طلبًا غير مكتمل',
	amountLessThanMerchantAvailable: 'يجب أن يكون المبلغ ضمن الحد المسموح به',
	amountLessThanYourBalanceLimit: 'يجب أن يكون المبلغ أقل من رصيدك',
	orderWith: 'الصفقة مع',
	receivingPaymentFromBuyer: 'استلام المبلغ من المشتري',
	releasePaymentToBuyer: 'تحرير المبلغ للمشتري ',
	opened: 'افتتح',
	appeal: 'تقديم شكوى',
	expired: 'منتهي الصلاحية',
	placed: 'قيد المعالجة',
	unknown: 'غير معروف',
	pleaseWaitUntilMerchantAcceptOrder: 'يرجى الانتظار لحين موافقة التاجر على الطلب',
	pleaseAcceptTheOrderBeforeItsExpired: 'يرجى قبول الطلب قبل انتهاء صلاحيته',
	thisOrderHasBeenCanceled: 'تم إلغاء هذا الطلب',
	thisOrderHasBeenExpired: 'لقد انتهت صلاحية هذا الطلب',
	thisOrderHasBeenRejected: 'لقد تم رفض هذا الأمر',
	thisOrderIsInAppeal: 'هذا الأمر قيد الاستئناف',
	theOrderStateIsUnknown: 'حالة الطلب غير معروفة',
	writeReviewAboutTheMerchant: 'اكتب تعليقًا عن التاجر',
	reviewIsRequired: 'النقيم مطلوب',
	paymentDetails: 'تفاصيل الدفع',
	paymentCompleted: 'تم التسديد',
	accept: 'يقبل',
	reject: 'يرفض',
	finishedSuccessfully: 'تم الانتهاء بنجاح',
	chatIsDisabled: 'الدردشة غير متاحة',
	areYouSureYouWantToAcceptOrder: 'هل أنت متأكد أنك تريد قبول هذا الطلب؟',
	cancelThisOrder: 'إلغاء هذا الطلب',
	inzoDoesnNotProvideAutomaticRefunds:
		'{{company_name}} لا توفر  عمليات استرداد تلقائية. لا تقم بإلغاء طلبك إذا كنت قد دفعت ولكنك لم تستلم المبلغ المدفوع، قم برفع شكوى إذا كنت بحاجة إلى مساعدة.',
	waitForReceivingConfirmation: 'يرجى انتظار تأكيد استلام الأموال',
	gotIt: 'فهمت ذلك',
	makeSureToContactMerchant:
		'يرجى التأكد من الاتصال بالتاجر قبل إجراء أي دفعة. انزو لن تكون مسؤولة عن أي نوع من الخسارة.',
	makeSureToContactClient:
		'يرجى التأكد من الاتصال بالعميل قبل إجراء أي دفعة. انزو لن تكون مسؤولة عن أي نوع من الخسارة.',
	importNote: 'ملاحظة مهمة',
	paymentCompletedNote1: 'يرجى التأكد من أنك قمت بتحويل الأموال بنجاح إلى البائع.',
	paymentCompletedNote2: 'ملحوظة: لا تقم بالمتابعة إذا لم تكن قد أكملت الدفع/ فقد يؤدي ذلك إلى تعليق الحساب.',
	sucessfullyCompletedPaymentToSeller: 'لقد أكملت الدفع للبائع بنجاح.',
	paymentReceivedNote1: 'يرجى التأكد من أنك قد تلقيت الأموال بنجاح من المشتري.',
	paymentReceivedNote2: 'ملحوظة: لا تقم بالمتابعة إذا لم تستلم الدفعة/ فقد يؤدي ذلك إلى تعليق الحساب.',
	conofirmYouReceivedPayment: 'تأكيد أنك قد تلقيت الدفعة.',
	sucessfullyCompletedPaymentToBuyer: 'لقد أكملت الدفع للمشتري بنجاح.',
	areYouSureYouWantToReject: 'هل أنت متأكد أنك تريد رفض هذا الطلب ؟',
	transferWasSentSuccessfully: 'تم إرسال النقل بنجاح إلى الجزء الآخر',
	accepted: 'وافقت',
	finishedSuccessfuly: 'تم الانتهاء بنجاح',
	typeMessageHere: 'اكتب رسالة هنا',
	markAllAsRead: 'تمت القراءة',
	chooseMerchantFromYourCountry: 'اختر تاجرًا من بلدك',
	feedbackOnThisOrder: 'تعليقات على هذا الطلب',
	visit: 'مشاهده',
	newReview: 'تعليق جديد',
	seeOrderDetails: 'انظر تفاصيل الطلب',
	pleaseWaitUntilThePaymentIsReleased: 'يرجى الانتظار حتى يتم تحرير الدفع',
	waitUntilMerchantReleasesPayment: 'انتظر حتى يقوم التاجر بتحرير الدفعة',
	waitUntilClientTransfersPayment: 'انتظر حتى يقوم العميل بتحويل الدفعة',
	waitUntilMerchantConfirmsReceivingPayment: 'انتظر حتى يؤكد التاجر استلام الدفعة',
	waitUntilClientConfirmsReceivingPayment: 'انتظر حتى يؤكد العميل استلام الدفعة',
	paymentTransfered: 'تم تحويل المبلغ',
	inzoDoesNotProvideAutoRefund:
		'{{company_name}} لا توفر  عمليات استرداد تلقائية. يرجى تسجيل الدخول إلى حسابك للتحقق من حالة الدفع',
	iReceivedAmountFromBuyer: 'لقد تلقيت {{amount}} من المشتري.',
	iCheckedPaymentFrom: 'لقد تأكدت من أن الدفعة جاءت من {{userName}}',
	iAgreeToReleasePaymentToBuyer: 'أوافق على تحرير دفعتي للمشتري.',
	transferWasSentSuccessfullyClient: 'لقد قمت بشراء {{amount}} بنجاح من {{username}}',
	transferWasSentSuccessfullyMerchant: 'لقد قمت ببيع {{amount}} بنجاح إلى {{username}}',
	reviewSubmitted: 'تم تقديم التقيم بنجاح',
	notificationNewOrderMessage: `طلب {{orderType}} جديد #{{orderNumber}} بقيمة {{amount}} تم فتحه.`,
	notificationOrderNewMessage: `رسالة جديدة في الطلب #{{orderNumber}} في {{createdAt}}`,
	notificationOrderExpiryWarning: `طلبك #{{orderNumber}} على وشك الانتهاء في {{orderExpiresTimestamp}}`,
	notificationOrderExpired: `انتهى الطلب #{{orderNumber}}.`,
	notificationOrderStatusUpdate: `تغيير في حالة الطلب #{{orderNumber}}. {{orderStatusBefore}} → {{orderStatusAfter}}`,
	notificationOfferDisabled: `تم سحب عرضك {{offerType}} بسبب {{reason}}`,
	notificationAccountBalanceLow: `رصيد حساب التاجر منخفض. الرصيد الحالي: {{amount}}`,
	notificationSellAvailableLow: `كمية عرض البيع التي حددتها على وشك النفاد. قم بتحديث الكمية لضمان صلاحية العرض. الرصيد الحالي للبيع المتاح: {{sellAvailable}}`,
	seeMore: 'انظر المزيد',
	noNotificationsFound: 'لم يتم العثور على اشعارات',
	totalOrder: 'جميع الطلبات',
	offerType: 'نوع الطلب',
	offerNotAvailable: 'هذا العرض غير متاح في الوقت الحالي.',
	errorProccessingMT5: 'حدث خطأ أثناء معالجة الطلب',
	amountDosentSatisfyLimit: 'المبلغ لا يلبي الحد المسموح به للعرض!',
	noteErrorMessage: 'يجب ألا تزيد الملاحظة عن 1000 حرف!',
	type: 'النوع',
	resonsForCancellation: 'سبب الغاء الطلب ',
	resonsForCancellationNote: 'لتجنب الخسارة لا تقم بإلغاء الطلب بعد إجراء الدفع,انزو ليست مسؤولة عن أي نوع من الخسارة',
	unableToMakePayment: 'غير قادر على إجراء الدفع',
	dontKnowHowToPay: 'لا أعرف كيف أدفع',
	discussedWithSeller: 'ناقشت مع البائع عدم المتابعة',
	poorSellerAttitude: 'سوء معاملة البائع',
	cantContactSeller: 'لا يمكن الاتصال بالبائع',
	suspectSellerFraud: 'تشك في احتيال البائع',
	others: 'آخرون',
	userSuspend: 'تم تعطيل حسابك',
	userBlock: 'تم حظر حسابك',
	userUnblock: 'تم رفع الحظر على حسابك',
	userUnsuspend: 'تم رفع التعطيل على حسابك',
	accessDenied: 'تم الرفض',
	chatHasBeenClosed: 'تم إغلاق الدردشة',
	emailAlreadyExist: 'البريد الالكتروني موجود مسبقا',
	phoneNumberDoesNotMatchTheRequirement: 'رقم الهاتف لا يطابق المتطلبات',
	newPasswordAndConfirmPasswordNotSame: 'كلمة المرور الجديدة وتأكيد كلمة المرور يجب ان يتطابقوا',
	userIsBlocked: 'تم حظر المستخدم',
	userIsSuspended: 'تم تعليق المستخدم',
	invalidOTP: 'كلمة المرور لمرة واحدة غير صالحة',
	merchantNotFound: 'لم يتم العثور على التاجر',
	cancelAppeal: 'الغاء الشكوى',
	revert: 'تراجع',
	release: 'اطلق الدفعة',
	copiedSuccessfully: 'تم النسخ بنجاح',
	userSuspended: 'المستخدم موقوف',
	merchantNewReview: 'تقيم جديد',
	withdrawal: 'سحب',
	deposit: 'ايداع',
	chatnow: 'تواصل الان',
	expireIn: 'ينتهي في خلال',
	releasePayment: 'تحرير المبلغ للمشتري',
	paywith: '{{method}}ادفع عن طريق',
	notFound: 'رقم الطلب غير موجود',
	invalidType: 'امتداد الملف غير صالح. يرجى تحميل ملف بتنسيق JPG، JPEG، PNG، أو PDF.',
	fileExceedLimit: 'حجم الملف يتجاوز الحد المسموح به (5 ميجابايت).',
	accountLimit: 'حد الحساب',
	maxValueAvailable: 'المبلغ أكبر من المتاح',
	cannotEnableOffer: 'لا يمكن تفعيل العرض بسبب عدم وجود رصيد كافي',
	show: 'عرض',
	entries: 'الإدخالات',
	sortBy: 'ترتيب ',
	startDate: 'بدايه التاريخ',
	endDate: 'نهايه التاريخ',
	enterYourOtp: 'أدخل رمز التحقق الخاص ببريدك الإلكتروني',
	amountmustbelessThanavailabel: 'يجب أن يكون المبلغ أقل من الرصيد المتاح {{amount}} {{currency}}',
	amountmustbeBiggerThanMinmum: 'يجب أن يكون المبلغ أكبر من الحد الأدنى',
	minAllowedValue: 'يجب أن يكون الحد الأدنى للمبلغ أكبر من أو يساوي {{amount}} {{currency}}',
	maxAllowedValue: 'يجب أن يكون الحد الأقصى للمبلغ أكبر من أو يساوي {{amount}} {{currency}}',
	priceLow: 'اقل سعر',
	priceHight: 'اعلى سعر',
	didNotreceiveOtp: 'لم تستلم الرمز؟',
	resend: 'اعاده ارسال',
	withdrawalOffer: 'عرض سحب',
	depositOffer: 'عرض إيداع',
	NEW_ORDER: 'طلب جديد',
	ORDER_NEW_MESSAGE: 'رسالة جديدة في الطلب',
	ORDER_EXPIRY_WARNING: 'تحذير انتهاء الطلب',
	ORDER_EXPIRED: 'انتهت صلاحية الطلب',
	ORDER_STATUS_UPDATE: 'تحديث حالة الطلب',
	OFFER_DISABLED: 'العرض تم تعطيله',
	ACCOUNT_BALANCE_LOW: 'رصيد الحساب منخفض',
	SELL_AVAILABLE_LOW: 'توفر البيع منخفض',
	MERCHANT_NEW_REVIEW: 'تقييم تاجر جديد',
	USER_SUSPEND: 'تعليق المستخدم',
	USER_UNSUSPEND: 'إلغاء تعليق المستخدم',
	USER_BLOCK: 'حظر المستخدم',
	USER_UNBLOCK: 'رفع حظر المستخدم',
	unitPriceChange: 'تغير سعر الوحدة. يرجى مراجعة الأسعار الجديدة قبل تقديم الطلب',
	allowBalance: 'يرجى إيداع {{amount}} دولار أمريكي لتمكين العروض.',
	priceChange: 'تغير السعر',
	YouReceive: 'تستلم',
	Youpay: 'تدفع',
	Seeorderdetails: 'رؤية تفاصيل الطلب',
	INSUFFICIENT_BALANCE: 'رصيد غير كافي',
	COOLDOWN: 'فترة خمول',
	BY_ADMIN: 'بواسطة المشرف',
	termsAndConditionError: 'لم يقبل التاجر الشروط والأحكام',
	termsAndConditionsTitle: 'شروط وأحكام تجار P2P {{company_name}}',
	acceptTerms: 'أوافق على الشروط والأحكام',
	aggree: 'موافق',
	RegistrationAndqualification: 'التسجيل والتأهيل',
	RegistrationAndqualification1: 'يجب على التجار التسجيل كتاجر P2P {{company_name}} وتقديم المعلومات المطلوبة للتأهيل.',
	RegistrationAndqualification2:
		'يجب على التجار تقديم وثائق هوية صالحة مع إثبات الإقامة، وسيتم عقد اجتماع مع التاجر لمصادقة حسابه.',
	RegistrationAndqualification3: 'التأهيل يخضع لموافقة الشركة، ويمكن تعديل الشروط في أي وقت.',
	TransactionsAndfees: 'المعاملات والرسوم',
	TransactionsAndfees1: 'يجب على التجار الامتثال للرسوم والأسعار المعتمدة لخدمة P2P {{company_name}} Merchant.',
	TransactionsAndfees2: 'تحتفظ الشركة بالحق في تحديث الرسوم والأسعار وإعلام التجار بذلك.',
	CommitmentTosafety: 'التزام بالسلامة',
	CommitmentTosafety1: 'يجب على التجار اتخاذ تدابير أمان صارمة لحماية معلومات العملاء والمعاملات.',
	CommitmentTosafety2: 'يجب توفير بيئة آمنة ومأمونة لإجراء المعاملات.',
	Compliancewithlawsandregulations: 'الامتثال للقوانين واللوائح',
	Compliancewithlawsandregulations1:
		'يجب على التجار الامتثال لجميع القوانين واللوائح المتعلقة بالخدمات المالية و P2P {{company_name}} Merchant.',
	Positiveinteractionwithcustomers: 'تفاعل إيجابي مع العملاء',
	Positiveinteractionwithcustomers1:
		'يجب على التجار التفاعل بشكل إيجابي مع العملاء وتقديم الدعم الفعّال والسرعة في تنفيذ الطلبات. يجب حل الشكاوى والمشاكل بسرعة وفعالية.',
	Terminationofservice: 'إنهاء الخدمة',
	Terminationofservice1: 'تحتفظ الشركة بالحق في إنهاء خدمة التاجر إذا لم يتم الامتثال للشروط والأحكام المتفق عليها.',
	send: 'ارسل',
	amountDoesNotSatisfyAccountGroupLimit: 'المبلغ لايلبي الحد المسموح في الحساب',
	limitReached: 'تجاوزت حد طلبات التاجر!',
	OTPIsrequired: 'الرمز السري مطلوب!',
	Pleaseuploadthepaymentproof: 'يرجى تحميل إثبات الدفع',
	ChooseFile: 'اختر الملف',
	selectPaymentProof: 'اختر إثبات الدفع',
	fileUploadRequired: 'الملف مطلوب',
	download: 'تحميل',
	live: 'نشط',
	submit: 'ارسال',
	back: 'الرجوع',
	of: 'من',
	items: 'عنصر',
	agent: 'العميل',
	tradersRoom: 'بوابه العميل',
	exchangeRateUpdate: 'تم تغيير سعر الصرف من {{oldExchangeRate}} إلى {{newExchangeRate}} بواسطة {{role}}.',
	EXCHANGE_RATE_UPDATE: 'تحديث سعر الصرف',
	ORDER_REJECTION_WARNING: 'تحذير رفض الطلب',
	sessionExpired: 'انتهت الجلسة',
	orderRejectionWarning: 'رفض الطلب مرة أخرى سيؤدي إلى تعطيل عروضك',
	suspendClient: 'تعليق العميل',
	suspendMerchant: 'تعليق التاجر',
	unsuspendMerchant: 'رفع تعليق التاجر',
	unsuspendClient: 'رفع تعليق العميل',
	restricted: 'مقيد',
	completionRate: 'اكتمال',
	otpexpired: 'لقد انتهت صلاحية رمز OTP!',
	reachedotplimit: 'لقد وصلت إلى الحد الأقصى لمحاولات رمز OTP!!',
	annoncement: 'سيكون هنالك صيانة في النظام مجدولة من {{from}} - {{to}}',
	validationChatMessage: ' الأحرف الخاصة <, >, & غير مسموح بها.',
};
