import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export function NoteConfirmationPayment({
	showImportantNotePayment,
	setShowshowImportantNotePayment,
}: {
	showImportantNotePayment: string;
	setShowshowImportantNotePayment: (value: string) => void;
}) {
	const { t } = useTranslation();

	return (
		<Modal
			onCancel={() => setShowshowImportantNotePayment('')}
			open={Boolean(showImportantNotePayment)}
			footer={null}
			title={
				<div className=''>
					<div className='flex justify-center relative my-4 text-2xl'>
						<ExclamationCircleOutlined />
					</div>
					<p className='flex justify-center mt-[-15px] mb-0 text-[#0F2147] text-[20px]'>{t<string>('importantNote')}</p>
				</div>
			}
		>
			<div className='flex justify-start items-center mb-4'>
				<p className='ps-[20px] text-[#333] p-0 m-1'>{showImportantNotePayment}</p>
			</div>

			<button
				className='form-btn'
				style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
				onClick={() => setShowshowImportantNotePayment('')}
			>
				{t<string>('gotIt')}
			</button>
		</Modal>
	);
}
