import React from 'react';
import './Navbar.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import Languages from '../Languages/Languages';
import { useMainContext } from '../../store/MainContext';
import { deleteUserInfo, getUserInfo } from '../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { resetMainState } from '../../assets/config/initial-main-state';
import { getInitials } from '../../helpers/getStringInitials';
import Notification from '../Notification/Notification';

const NavBarIcons = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mainState, setMainState } = useMainContext();
	const userMenuDetails = React.useMemo(
		() => ({
			email: mainState?.email ?? '',
			fullName: mainState?.fullName ?? '',
			countryCode: mainState?.countryCode ?? '',
		}),
		[mainState.email, mainState.fullName, mainState?.countryCode],
	);
	const isLoggedIn = React.useMemo(() => mainState.isLoggedIn, [mainState.isLoggedIn]);
	const isMerchant = React.useMemo(() => mainState.isMerchant, [mainState.isMerchant]);
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	const userInfo = getUserInfo();
	const hanelLogOut = React.useCallback(() => {
		const token = userInfo?.token;
		if (token) {
			axios.post(API_ENDPOINTS.logout, { token }, { headers: { Authorization: `Bearer ${token}` } }).then((_) => {
				deleteUserInfo();
				setMainState(resetMainState);
				navigate('/sign-in');
			});
		}
	}, [mainState.token, navigate, setMainState]);

	return (
		<div className='flex items-center mt-2 md:mt-0'>
			<Languages />
			{isLoggedIn && <Notification />}
			{isLoggedIn && (
				<Popover
					placement={getUserLanguageInCookie() === 'ar' ? 'topLeft' : 'topRight'}
					trigger='click'
					open={isMenuOpen}
					onOpenChange={(newOpen) => setIsMenuOpen(newOpen)}
					content={
						<div className='flex flex-col'>
							<div className='user-menu-details'>
								<span className='user-menu-details-initials'>{getInitials(userMenuDetails.fullName)}</span>
								<div className='user-menu-details-content'>
									<div className='user-menu-name-container'>
										<p className='name'>{userMenuDetails.fullName}</p>
										{(
											<img
												src={`https://flagsapi.com/${userMenuDetails.countryCode}/shiny/64.png`}
												alt='country'
												className='country-flag'
											/>
										) ?? <>({userMenuDetails.countryCode})</>}
									</div>
									<p className='email'>{userMenuDetails.email}</p>
								</div>
							</div>
							{isMerchant ? (
								<Button
									onClick={() => {
										setIsMenuOpen(false);
										navigate(`/merchant?id=${userInfo?.id}`);
									}}
									className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
								>
									{t<string>('myProfile')}
								</Button>
							) : (
								<Button
									onClick={() => {
										setIsMenuOpen(false);
										navigate('/my-accounts');
									}}
									className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
								>
									{t<string>('myAccounts')}
								</Button>
							)}
							<Button
								onClick={() => {
									setIsMenuOpen(false);
									hanelLogOut();
								}}
								className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
							>
								{t<string>('logout')}
							</Button>
						</div>
					}
				>
					<Button className='p-0 m-0 border-none uppercase text-white text-xs font-medium flex items-center hover:text-white bg-transparent'>
						<UserOutlined style={{ fontSize: 16, color: '#fff' }} />
						<DownOutlined className='w-2' style={{ fontSize: 10, color: '#fff' }} />
					</Button>
				</Popover>
			)}
		</div>
	);
};

export default NavBarIcons;
