import { useEffect, useMemo, useState } from 'react';
import './OrderLifeCycle.scss';
import OrderLifeCycleBody from './components/OrderLifeCycleBody/OrderLifeCycleBody';
import OrderLifeCycleHeader from './components/OrderLifeCycleHeader/OrderLifeCycleHeader';
import OrderLifeCycleState from './components/OrderLifeCycleState/OrderLifeCycleState';
import { deleteUserInfo, getUserInfo } from '../../helpers/localStorageHandler';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import dayjs from 'dayjs';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { ActionType } from './types/ActionType';
import { MessageType } from '../../models/MessageType';
import { OrderStateType } from './types/OrderStateType';
import { OrderDetailsType } from './types/OrderDetailsType';
import { useLocation, useNavigate } from 'react-router-dom';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm:ss A';

function OrderLifeCycle() {
	const [notFound, setNotFound] = useState('');
	const [otpVlaid, setOtpValid] = useState({ value: false });
	let [stompClient, setStompClient] = useState<Stomp.Client | null>(null);
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const { t } = useTranslation();
	const token = getUserInfo()?.token;
	const location = useLocation();
	const orderNumber = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get('id') ?? '';
	}, [location.search]);
	const isMerchant = useMemo(() => getUserInfo()?.isMerchant ?? false, []);
	const [orderDetails, setOrderDetails] = useState<OrderDetailsType | null>(null);
	const [loading, setLoading] = useState(false);
	const userName = useMemo(
		() => (isMerchant ? orderDetails?.clientDetails?.clientFullName || '' : orderDetails?.merchant?.fullName || ''),
		[isMerchant, orderDetails],
	);
	useEffect(() => {
		document.getElementById('tidio-chat-iframe')?.style.setProperty('display', 'none');

		return () => {
			document.getElementById('tidio-chat-iframe')?.style.setProperty('display', 'block');
		};
	}, []);

	let cleanupFunction = () => {};
	useEffect(() => {
		if (isOnline) {
			axios
				.get(
					API_ENDPOINTS.orderDetails
						.replace('%side%', isMerchant ? 'merchant' : 'client')
						.replace('%orderNumber%', orderNumber),
					{ headers: { Authorization: `Bearer ${token}` } },
				)
				.then((response) => {
					setOrderDetails(response.data);
					const socket = new SockJS(API_ENDPOINTS.chat);
					const stompClient = Stomp.over(socket);
					setStompClient(stompClient);
					cleanupFunction = () => {
						if (stompClient?.connected) {
							stompClient.disconnect(() => {
								return true;
							});
						}
					};
				})
				.catch((err: any) => {
					if (err?.response?.status === 404) setNotFound(err);
				});
		} else {
			setStompClient(null);
		}

		return () => {
			cleanupFunction();
		};
	}, [location.search, isOnline]);

	useEffect(() => {
		const handleOnline = () => {
			setIsOnline(true);
		};

		const handleOffline = () => {
			setIsOnline(false);
		};

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	useEffect(() => {
		document.body.classList.remove('body-no-scroll');
	}, []);
	const getOrderDetails = () => {
		axios
			.get(
				API_ENDPOINTS.orderDetails
					.replace('%side%', isMerchant ? 'merchant' : 'client')
					.replace('%orderNumber%', orderNumber),
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then((response) => setOrderDetails(response.data))
			.catch((err: any) => {
				if (err?.response?.status === 404) setNotFound(err);
			});
	};

	const triggerAction = (action: ActionType, reasons?: string, otp?: string) => {
		setLoading(true);
		const payload = reasons ? { cancellationReason: reasons } : otp ? { otp } : {};
		axios
			.post(API_ENDPOINTS.orderAction.replace('%orderNumber%', orderNumber).replace('%action%', action), payload, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then(() => {
				setOtpValid({ value: true });
				const actionMessage = isMerchant
					? { merchantAction: action, type: MessageType.ACTION }
					: { clientAction: action, type: MessageType.ACTION };

				// When action is triggered successfully, we send ACTION message in the chat
				// When the ACTION message is received we re-fetch order details on both sides
				if (stompClient?.connected) {
					stompClient?.send(
						API_ENDPOINTS.sendOrderChatMsgTopic.replace('%orderNumber%', orderNumber),
						{},
						JSON.stringify(actionMessage),
					);
				} else {
					const socket = new SockJS(API_ENDPOINTS.chat);
					const stompClient = Stomp.over(socket);
					setStompClient(stompClient);
					stompClient?.send(
						API_ENDPOINTS.sendOrderChatMsgTopic.replace('%orderNumber%', orderNumber),
						{},
						JSON.stringify(actionMessage),
					);
				}
			})
			.catch((err: any) => {
				if (err?.response?.data.code === 2037 || err?.response?.data?.code === 2046) setOtpValid({ value: false });
				getOrderDetails();
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className='order-life-cycle-page'>
			{notFound ? (
				<Result status='404' title={t<string>('notFound')} />
			) : (
				<>
					<OrderLifeCycleHeader
						orderType={orderDetails?.type || 'BUY'}
						user={userName}
						orderNumber={orderNumber}
						openTime={dayjs(orderDetails?.createdAt).format(DATE_TIME_FORMAT)}
					/>
					<OrderLifeCycleState
						orderState={orderDetails?.status as OrderStateType}
						isMerchant={isMerchant}
						orderType={orderDetails?.type || 'BUY'}
						expireRemainingDuration={orderDetails?.expireRemainingDuration ?? 0}
						client={orderDetails?.clientDetails?.clientFullName ?? ''}
						merchant={orderDetails?.merchant?.fullName ?? ''}
						orderNo={orderNumber}
						getOrderDetails={getOrderDetails}
					/>
					<OrderLifeCycleBody
						loading={loading}
						orderNumber={orderNumber}
						isMerchant={isMerchant}
						orderState={orderDetails?.status as OrderStateType}
						userName={userName}
						note={orderDetails?.note || ''}
						orders={orderDetails?.merchant?.totalOrders || 0}
						reviews={orderDetails?.merchant?.totalReviews || 0}
						rating={orderDetails?.merchant?.rating || 0}
						feedback={orderDetails?.review ?? null}
						orderBody={{
							unitPrice: `${orderDetails?.price}`,
							quantity: `${orderDetails?.quantity?.toLocaleString()}`,
							amount: `${orderDetails?.amount?.toLocaleString()}`,
							paymentMehodId: orderDetails?.paymentMethod || 0,
							accountNumber: `${orderDetails?.accountDetails?.number}`,
							accountGroup: `${orderDetails?.accountDetails?.accountGroup}`,
						}}
						merchantDetails={orderDetails?.merchant}
						actions={orderDetails?.actions || []}
						orderType={orderDetails?.type || 'BUY'}
						merchantId={`${orderDetails?.merchant?.merchantId}` || ''}
						triggerAction={triggerAction}
						stompClient={stompClient}
						getOrderDetails={getOrderDetails}
						clientOrder={orderDetails?.clientDetails?.totalOrders ?? 0}
						clientCompletedOrders={orderDetails?.clientDetails?.completedOrders ?? 0}
						canReview={orderDetails?.canReview ?? false}
						paymentProof={orderDetails?.paymentProof ?? false}
						paymentProofFileId={orderDetails?.paymentProofFileId ?? ''}
						paymentProofFileLabel={orderDetails?.paymentProofFileLabel ?? ''}
						otpVlaid={otpVlaid}
					/>
				</>
			)}
		</div>
	);
}

export default OrderLifeCycle;
