import { Checkbox, Modal, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function RejectDialog({
	showRejectDialog,
	setShowRejectDialog,
	handelReject,
	loading,
}: {
	showRejectDialog: boolean;
	setShowRejectDialog: (value: boolean) => void;
	handelReject: () => void;
	loading: boolean;
}) {
	const { t } = useTranslation();
	const [isRejectOrderChecked, setIsRejectOrderChecked] = useState(false);

	return (
		<Modal onCancel={() => setShowRejectDialog(false)} open={showRejectDialog} footer={null}>
			<Checkbox
				onChange={(e) => setIsRejectOrderChecked(e.target.checked)}
				style={{ marginBottom: '20px' }}
				checked={isRejectOrderChecked}
			>
				{t<string>('areYouSureYouWantToReject')}
			</Checkbox>
			<Spin spinning={loading}>
				<button
					disabled={!isRejectOrderChecked}
					className='form-btn'
					style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
					onClick={() => {
						handelReject();
						setShowRejectDialog(false);
						setIsRejectOrderChecked(false);
					}}
				>
					{t<string>('yes')}
				</button>
			</Spin>
		</Modal>
	);
}
