import React from 'react';
import { Button, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../../assets/config/validation-regex';
import { SignInValues } from '../../models/SignIn';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { deleteUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import { useMainContext } from '../../store/MainContext';
import { resetMainState } from '../../assets/config/initial-main-state';
import './SignIn.scss';
import { encryptData } from '../../helpers/passwordEncryptionHandler';
import ReCAPTCHA from 'react-google-recaptcha';
import { TermsAndCondtions } from './TermsAndConditions';
import { useHideTidioChat } from '../../hooks/useHideTidioChat';
import { useConfigurationContext } from 'store/ConfigurationContext';
import { companyWebsite as defaultcompanyWebsite } from '../../assets/constant/fallback-config';

const siteKey = process.env.REACT_APP_SITE_KEY || '';

function SignIn() {
	const { t, ...options } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { mainState, setMainState } = useMainContext();
	const [signInFormLoading, setSignInFormLoading] = React.useState(false);
	const [signInFormDisabled, setSignInFormDisabled] = React.useState(true);
	const [recaptcha, setRecaptcha] = React.useState(false);
	const [isExpired, setIsExpired] = React.useState(false);
	const [showTerms, setShowTerms] = React.useState(false);
	const [acceptTerms, setAccetTerms] = React.useState(false);
	const { configurationState } = useConfigurationContext();
	// Add Tidio chat
	const companyWebsite =
		configurationState.find((item) => item.configKey === 'company_website')?.value ?? defaultcompanyWebsite;

	const currentYear = React.useMemo(() => new Date().getFullYear(), []);

	useHideTidioChat();

	React.useEffect(() => {
		if (mainState.isLoggedIn) {
			if (location.pathname === '/sign-in') {
				navigate('/');
			}
		}
	}, [mainState.isLoggedIn, location.pathname]);

	const onSubmit = async (signInValues: SignInValues) => {
		setSignInFormLoading(true);
		const encryptedPassword = encryptData(signInValues.password, signInValues.email.toLocaleLowerCase());
		axios
			.post(API_ENDPOINTS.signin, {
				...signInValues,
				email: signInValues.email.toLocaleLowerCase(),
				password: encryptedPassword,
				termsAccepted: acceptTerms,
			})
			.then((res) => {
				const userData = res.data;
				setMainState({
					...mainState,
					email: userData?.email,
					fullName: userData?.fullName,
					isLoggedIn: true,
					isMerchant: userData?.isMerchant,
					token: userData?.token,
					countryCode: userData?.countryCode,
				});
				setUserInfo(res.data);
				setSignInFormLoading(false);
				navigate('/merchants');
			})
			.catch((err) => {
				deleteUserInfo();
				setMainState(resetMainState);
				setSignInFormLoading(false);
				if (err.response?.data.code === 2043) {
					setShowTerms(true);
				}
			});
	};

	const onFormChange = (_: any, signInValues: SignInValues) => {
		setSignInFormDisabled(
			!EMAIL_VALIDATION.test(signInValues?.email) || !PASSWORD_VALIDATION.test(signInValues?.password),
		);
	};

	const handleRecaptchaChange = (value: string | null) => {
		if (value) {
			setRecaptcha(true);
			setIsExpired(false);
		}
	};

	return (
		<>
			<section className='signin-section'>
				<div className='form-container w-100 sm:w-[420px] m-5'>
					<div>
						<h1 className='form-container-heading'>{t<string>('welcomeBack')}👋</h1>
						<p className='form-container-subheading'>{t<string>('loginToYourAccount')}</p>
					</div>
					<Form
						name='basic'
						layout='vertical'
						onFinish={onSubmit}
						onValuesChange={onFormChange}
						autoComplete='off'
						requiredMark='optional'
					>
						<Form.Item
							label={t<string>('email')}
							name='email'
							rules={[
								{ required: true, message: t<string>('emailRequired') },
								{ pattern: EMAIL_VALIDATION, message: t<string>('emailMsg') },
							]}
						>
							<Input placeholder={t<string>('pleaseEnterYourEmail')} />
						</Form.Item>
						<Form.Item
							label={
								<div className='password-label-container'>
									<span>{t<string>('password')}</span>
								</div>
							}
							name='password'
							rules={[
								{ required: true, message: t<string>('passwordRequired') },
								{
									pattern: PASSWORD_VALIDATION,
									message: t<string>('passwordMsg'),
								},
							]}
						>
							<Input.Password placeholder={t<string>('enterPassword')} />
						</Form.Item>
						<Form.Item>
							<Button
								className='form-btn'
								type='primary'
								htmlType='submit'
								disabled={signInFormDisabled || !recaptcha || isExpired}
								loading={signInFormLoading}
							>
								{t<string>('signIn')}
							</Button>
						</Form.Item>
						<div className='links'>
							{t<string>('notRegistered')}{' '}
							<Link
								target='_blank'
								to='https://inzo.co/open-live-account?accountType=standard&platform=mt5'
								className='link'
							>
								{t<string>('createAnAccount')}
							</Link>{' '}
							{t<string>('or')}{' '}
							<Link target='_blank' to='https://inzo.co/open-merchant-account' className='link'>
								{t<string>('becomeMerchant')}
							</Link>
						</div>
						<div className='links'>
							{t<string>('readOur')}{' '}
							<Link
								target='_blank'
								to='https://my.inzo.co/public_media/03/d0/03d6b120-8365-11ec-9556-415712c08dd0.pdf'
								className='link'
							>
								{t<string>('termsAndConditions')}
							</Link>
						</div>
						<p className='warning-note'>{t<string>('signinWarningNote')}</p>
						<p className='all-rights-reserved'>
							© {currentYear}{' '}
							<a className='text-[#757575] text-xs no-underline' href={`https://${companyWebsite}.com`} target='_blank'>
								{companyWebsite}
							</a>{' '}
							{t<string>('allRightsReserved')}
						</p>
					</Form>
				</div>
				<ReCAPTCHA
					sitekey={siteKey}
					onChange={handleRecaptchaChange}
					onExpired={() => setIsExpired(true)}
					hl={options.i18n.language}
					key={options.i18n.language}
				/>
			</section>
			{<TermsAndCondtions showTerms={showTerms} setShowTerms={setShowTerms} isAccetingTerms={setAccetTerms} />}
		</>
	);
}

export default SignIn;
