import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import { Table } from 'antd';
import { getMyAccountsColumns } from '../../helpers/myAccountsTableColumns';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { MyAccount } from '../../models/MyAccounts';
import SubHeader from '../../components/SubHeader/SubHeader';
import './MyAccount.scss';

const MyAccounts8 = () => {
	const { t } = useTranslation();
	const [myAccounts, setMyAccounts] = useState<MyAccount[] | null>(null);
	const [myAccountsLoading, setMyAccountsLoading] = useState(false);

	useEffect(() => {
		setMyAccountsLoading(true);
		const token = getUserInfo()?.token;

		axios
			.get(API_ENDPOINTS.accounts, { headers: { Authorization: `Bearer ${token}` } })
			.then((result) => {
				setMyAccounts(result.data);
				setMyAccountsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setMyAccountsLoading(false);
			});
	}, []);
	return (
		<div className='my-accounts'>
			<SubHeader title={t<string>('myAccounts')} description={t<string>('myAccountsSubtitle')} />
			<div className='max-w-[1440px] mx-auto px-4 pt-[30px] pb-10 bg-white'>
				<Table
					columns={getMyAccountsColumns(t)}
					dataSource={myAccounts as MyAccount[]}
					loading={myAccountsLoading}
					tableLayout='auto'
				/>
			</div>
		</div>
	);
};

export default MyAccounts8;
