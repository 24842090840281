import { ClockCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

export function MoenyWaitingDialog({
	showMoenyWatingDialog,
	setShowMoenyWatingDialog,
}: {
	showMoenyWatingDialog: boolean;
	setShowMoenyWatingDialog: (value: boolean) => void;
}) {
	const { t } = useTranslation();

	return (
		<Modal
			title={
				<div>
					<ClockCircleOutlined
						style={{ fontSize: '40px', textAlign: 'center', margin: '16px auto', display: 'block' }}
					/>
				</div>
			}
			onCancel={() => setShowMoenyWatingDialog(false)}
			open={showMoenyWatingDialog}
			footer={null}
		>
			<h4 style={{ textAlign: 'center' }}>{t<string>('waitForReceivingConfirmation')}</h4>
			<button
				className='form-btn'
				style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
				onClick={() => setShowMoenyWatingDialog(false)}
			>
				{t<string>('gotIt')}
			</button>
		</Modal>
	);
}
