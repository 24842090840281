import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClockCircleOutlined } from '@ant-design/icons';

export function NoteConfirmationDialog({
	showImportantNoteDialogMessage,
	setShowImportantNoteDialogMessage,
}: {
	showImportantNoteDialogMessage: string;
	setShowImportantNoteDialogMessage: (value: string) => void;
}) {
	const { t } = useTranslation();

	return (
		<Modal
			onCancel={() => setShowImportantNoteDialogMessage('')}
			open={Boolean(showImportantNoteDialogMessage)}
			footer={null}
		>
			<div className='flex justify-start items-center text-2xl'>
				<ClockCircleOutlined />
				<p className='ps-[10px] font-bold text-[#333] '>{showImportantNoteDialogMessage}</p>
			</div>

			<button
				className='form-btn'
				style={{ cursor: 'pointer', margin: '0 auto', maxWidth: '100px', height: '36px', fontWeight: 400 }}
				onClick={() => setShowImportantNoteDialogMessage('')}
			>
				{t<string>('gotIt')}
			</button>
		</Modal>
	);
}
